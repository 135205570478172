import { FaTrophy } from "react-icons/fa";
import { useTimeRange } from "../../../context/TimeRangeContext";
import { useEffect, useState } from "react";

const PastWinners = () => {
  const [employees, setEmployees] = useState([]);
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchPastWinners = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage
      if (!token) return;

      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/rewards/present-and-past/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Extract top 3 past winners
        const pastWinners = data.previous_period_winners
          .slice(0, 3)
          .map((item, index) => ({
            name: item.employee.name,
            reviews: item.total_feedback || 0,
            rank: index + 1,
            image:
              item.employee.company.profile_image ||
              "https://randomuser.me/api/portraits/men/10.jpg",
          }));

        setEmployees(pastWinners);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchPastWinners();
  }, [timeRange]);

  if (employees.length < 3) {
    return <p>Loading...</p>;
  }
  return (
    <div className="bg-white text-black rounded-lg h-[50vh] shadow-lg p-6 w-full mx-auto">
      <div className="flex items-start flex-col gap-2 mb-6">
        <div className="flex">
          <FaTrophy className="text-yellow-500 text-2xl mr-2" />
          <h2 className="text-xl font-semibold">Past Winners</h2>
        </div>
        <p>
          These are{" "}
          {timeRange === "day" ? "yesterday's" : `last ${timeRange}'s`} top
          employees
        </p>
      </div>

      <div className="sub-blocks">
        <div className="sub-block-podium">
          <div className="absolute -top-40 left-4 flex flex-col items-center space-y-1">
            <img
              src={employees[1].image}
              alt={employees[1].name}
              className="w-14 h-14 rounded-full border-2 border-white"
            />
            <p className="text-sm font-bold text-black">{employees[1].name}</p>
            <span className="text-xs text-center bg-blue-600 px-2 py-1 rounded-lg">
              {employees[1].reviews.toLocaleString()} <br />
              Reviews Generated
            </span>
          </div>

          <span>2</span>
        </div>

        <div className="sub-block-podium">
          <div className="absolute -top-40 left-4 mb-2 flex flex-col items-center">
            <img
              src={employees[0].image}
              alt={employees[0].name}
              className="w-16 h-16 rounded-full border-2 border-white"
            />
            <p className="text-sm font-bold text-black">{employees[0].name}</p>
            <span className="text-xs text-center bg-yellow-400 text-black px-2 py-1 rounded-lg">
              {employees[0].reviews.toLocaleString()} <br />
              Reviews Generated
            </span>
          </div>
          <span>1</span>
        </div>
        <div className="sub-block-podium">
          <div className="absolute -top-40 left-4 flex flex-col items-center space-y-1">
            <img
              src={employees[2].image}
              alt={employees[2].name}
              className="w-14 h-14 rounded-full border-2 border-white"
            />
            <p className="text-sm font-bold text-black">{employees[2].name}</p>
            <span className="text-xs text-center bg-blue-600 px-2 py-1 rounded-lg">
              {employees[2].reviews.toLocaleString()} <br /> Reviews Generated
            </span>
          </div>

          <span>3</span>
        </div>
      </div>
    </div>
  );
};

export default PastWinners;
