import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

function ManualSurveyCreator({ onCancel, onSurveySaved }) {
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    type: "",
    options: ["", "", "", ""], // Default 4 options for single/multiple choice
    range: "1-5", // For numeric choice questions
  });

  const addQuestion = () => {
    if (!currentQuestion.text || !currentQuestion.type) {
      toast.error("Please fill in the question and select a type.");
      return;
    }

    // Validate options for single/multi-choice questions
    if (["single", "multi_choice"].includes(currentQuestion.type)) {
      const uniqueOptions = new Set(
        currentQuestion.options.filter((opt) => opt.trim() !== "")
      );
      if (
        uniqueOptions.size !==
        currentQuestion.options.filter((opt) => opt.trim() !== "").length
      ) {
        toast.error(
          "Options cannot match. Please ensure all options are unique."
        );
        return;
      }
    }

    if (currentQuestion.type === "numeric_choice") {
      if (!currentQuestion.range) {
        toast.error("Please select a numeric range (1-5 or 1-10).");
        return;
      }
      const rangeLimit = currentQuestion.range === "1-5" ? 5 : 10;
      currentQuestion.options = Array.from(
        { length: rangeLimit },
        (_, i) => i + 1
      );
    }

    setQuestions((prev) => [...prev, currentQuestion]);
    setCurrentQuestion({
      text: "",
      type: "",
      options: ["", "", "", ""], // Reset default options
      range: "",
    });
    toast.success("Question added successfully!");
  };

  const saveSurvey = async () => {
    if (!title || questions.length === 0) {
      toast.error("Please provide a title and at least one question.");
      return;
    }

    const token = localStorage.getItem("token");

    const surveyData = {
      title,
      questions: questions.map((q) => ({
        question_text: q.text,
        question_type: q.type === "single" ? "single_choice" : q.type,
        choices: q.type === "paragraph" ? [] : [...q.options],
      })),
    };

    try {
      const response = await fetch(
        "https://zola.technology/api/company/surveys/add-survey/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(surveyData),
        }
      );

      if (response.ok) {
        toast.success("Survey saved successfully!");
        onSurveySaved(); // Notify parent to refresh surveys
        onCancel(); // Close modal
      } else {
        toast.error("Failed to save survey. Please try again.");
      }
    } catch (error) {
      console.error("Error saving survey:", error);
      toast.error("An error occurred while saving the survey.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50 p-8 md:p-0">
      <div className="bg-white dark:bg-gray-900 rounded-lg w-full max-w-3xl p-8 shadow-lg relative">
        <ToastContainer position="top-right" autoClose={3000} />

        {/* Modal Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
            Create Survey
          </h2>
          <button
            onClick={onCancel}
            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition duration-150"
            aria-label="Close Modal"
          >
            ✕
          </button>
        </div>

        <div className="space-y-6">
          {/* Title Input Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Survey Title
            </label>
            <input
              type="text"
              placeholder="Enter Survey Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring focus:ring-blue-500 dark:bg-gray-800 dark:text-gray-200 placeholder-gray-400 dark:placeholder-gray-500"
            />
          </div>

          {/* Survey Type Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Question Type
            </label>
            <select
              value={currentQuestion.type}
              onChange={(e) =>
                setCurrentQuestion((prev) => ({
                  ...prev,
                  type: e.target.value,
                }))
              }
              className="w-full px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none placeholder-gray-400 dark:placeholder-gray-500 dark:text-gray-200"
            >
              <option value="">Select Question Type</option>
              <option value="paragraph">Free response question</option>
              <option value="single">Single Choice Question</option>
              <option value="multi_choice">Multiple Choice Question</option>
              <option value="numeric_choice">Numeric Choice Question</option>
            </select>
          </div>

          {/* Question Input Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Question
            </label>
            <input
              type="text"
              placeholder="Write Question here."
              value={currentQuestion.text}
              onChange={(e) =>
                setCurrentQuestion((prev) => ({
                  ...prev,
                  text: e.target.value,
                }))
              }
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring focus:ring-blue-500 dark:bg-gray-800 dark:text-gray-200 placeholder-gray-400 dark:placeholder-gray-500"
            />
          </div>

          {/* Options Input Fields */}
          {["single", "multi_choice"].includes(currentQuestion.type) && (
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Options
              </label>
              <div className="grid grid-cols-2 gap-4">
                {currentQuestion.options.map((option, index) => (
                  <input
                    key={index}
                    type="text"
                    placeholder={`Option No. ${index + 1}`}
                    value={option}
                    onChange={(e) =>
                      setCurrentQuestion((prev) => {
                        const updatedOptions = [...prev.options];
                        updatedOptions[index] = e.target.value;
                        return { ...prev, options: updatedOptions };
                      })
                    }
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-700 rounded-lg focus:outline-none focus:ring focus:ring-blue-500 dark:bg-gray-800 dark:text-gray-200 placeholder-gray-400 dark:placeholder-gray-500"
                  />
                ))}
              </div>
            </div>
          )}

          {/* Add Question Button */}
          <button
            onClick={addQuestion}
            className="px-6 py-2 bg-blue-600 dark:bg-blue-500 text-white rounded-lg shadow hover:bg-blue-700 dark:hover:bg-blue-400 transition"
          >
            Add Question
          </button>
        </div>

        {/* Added Questions Section */}
        <div className="mt-6">
          <h4 className="text-lg font-semibold dark:text-gray-200 mb-4">
            Added Questions
          </h4>
          <div className="space-y-4 max-h-60 overflow-y-auto border border-gray-200 dark:border-gray-700 rounded-lg p-4 bg-gray-50 dark:bg-gray-800">
            {questions.map((q, idx) => (
              <div
                key={idx}
                className="p-4 border border-gray-300 dark:border-gray-700 rounded-lg bg-white dark:bg-gray-900 shadow-sm"
              >
                <div className="flex justify-between items-center">
                  <h5 className="text-md font-medium text-gray-900 dark:text-gray-200">
                    {`Q${idx + 1}: ${q.text}`}
                  </h5>
                  <span className="px-3 py-1 text-sm font-medium text-white rounded-lg bg-blue-500">
                    {q.type.replace("_", " ").toUpperCase()}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Modal Footer */}
        <div className="flex justify-between items-center mt-6 space-x-4">
          <button
            onClick={onCancel}
            className="px-6 py-3 border border-gray-200 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-700 transition duration-150"
          >
            Cancel
          </button>
          <button
            onClick={saveSurvey}
            className="px-6 py-2 bg-blue-600 dark:bg-blue-500 text-white rounded-lg shadow hover:bg-blue-700 dark:hover:bg-blue-400 transition duration-150"
          >
            Save Survey
          </button>
        </div>
      </div>
    </div>
  );
}

export default ManualSurveyCreator;
