import React, { useState } from "react";

const SubscriptionComponent = ({
  setSubscriptionType,
  setTransactionType,
  subscriptionType,
  transactionType,
  currencySymbol,
  monthlySubscription,
  yearlySubscription,
}) => {
  const handleSubscriptionChange = (event) => {
    const selectedSubscription = event.target.value;
    setSubscriptionType(selectedSubscription);

    // Update transaction type based on subscription selection
    if (selectedSubscription === "month") {
      setTransactionType("both");
    } else if (selectedSubscription === "year") {
      setTransactionType("both");
    } else {
      setTransactionType("payment");
    }
  };
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const numberOfLocations = parsedUserData.number_of_locations;
  console.log(numberOfLocations);
  return (
    <div className="p-6 bg-gray-100">
      <h2 className="text-xl font-semibold mb-4">Subscription Options</h2>
      <div className="flex space-x-4">
        <label className="w-1/2">
          <input
            type="radio"
            value="month"
            checked={subscriptionType === "month"}
            onChange={handleSubscriptionChange}
            className="hidden"
          />
          <div
            className={`p-4 border rounded-lg cursor-pointer ${
              subscriptionType === "month"
                ? "bg-black text-white"
                : "bg-white text-gray-900"
            }`}
          >
            <h3 className="text-lg font-bold">Monthly Subscription</h3>
            <p className="mt-2 text-sm">
              <span className="mr-1">{currencySymbol}</span>
              {numberOfLocations * monthlySubscription} per month
            </p>
          </div>
        </label>
        <label className="w-1/2">
          <input
            type="radio"
            value="year"
            checked={subscriptionType === "year"}
            onChange={handleSubscriptionChange}
            className="hidden"
          />
          <div
            className={`p-4 border rounded-lg cursor-pointer ${
              subscriptionType === "year"
                ? "bg-black text-white"
                : "bg-white text-gray-900"
            }`}
          >
            <h3 className="text-lg font-bold">Yearly Subscription</h3>
            <p className="mt-2 text-sm">
              {/* <span className="line-through text-gray-500">
                {currencySymbol}
                588
              </span> */}
              <span
                className={`ml-2 ${
                  subscriptionType === "year" ? "text-white" : "text-black"
                }`}
              >
                <span className="mr-1">{currencySymbol}</span>
                {numberOfLocations * yearlySubscription} per {subscriptionType}
              </span>
              <span className="ml-1 text-green-500">
                <span className="mr-1"></span>({currencySymbol}{" "}
                {currencySymbol === "KES"
                  ? 36000 - yearlySubscription
                  : `${monthlySubscription - 9}/MTH`}
                )
              </span>
            </p>
          </div>
        </label>
      </div>
      {/* <p>
      Enjoy a 7-day free trial on us. After the trial, your account will be charged automatically unless you cancel before it ends.
      </p> */}
    </div>
  );
};

export default SubscriptionComponent;
