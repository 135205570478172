import { useEffect, useState } from "react";
import { LuMessagesSquare } from "react-icons/lu";
import { BiSolidLike, BiSolidDislike } from "react-icons/bi";
import { FaHandFist } from "react-icons/fa6";
import {
  FaUser,
  FaCalendarAlt,
  FaCheckCircle,
  FaTimesCircle,
  FaPhoneAlt,
  FaStar,
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { PiThumbsUpFill } from "react-icons/pi";
import { PiThumbsDownFill } from "react-icons/pi";
import Overview from "./Overview";
import { useTimeRange } from "../../context/TimeRangeContext";
import { ToastContainer, toast } from "react-toastify";
import Subscription from "./Subscription";
import { useOutletContext } from "react-router-dom";

function Feedback() {
  const [activeTab, setActiveTab] = useState("responded");
  const { timeRange } = useTimeRange();

  const [filteredFeedback1, setFilteredFeedback1] = useState([]);
  const [categorizedFeedback, setCategorizedFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [nonRespondedTotal, setNonRespondedTotal] = useState(0); // Total non-responded feedback
  const [respondedTotal, setRespondedTotal] = useState(0); // Total responded feedback

  const [ratingData, setRatingData] = useState({
    rating_of_1_count: 0,
    rating_of_2_count: 0,
    rating_of_3_count: 0,
    rating_of_4_count: 0,
    rating_of_5_count: 0,
  });
  const [resolvedFeedbackData, setResolvedFeedbackData] = useState([]);
  const [nonResolvedFeedback, setNonResolvedFeedback] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const { isExpired } = useOutletContext();
  // const [filter, setFilter] = useState("month");
  const handleFilterChange = (event) => {
    setFilter(event.target.value); // Update state with the selected value
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchData = async (url, setData) => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setData(data);
      } catch (err) {
        setError(err.message);
      }
    };

    // Fetch totals for non-responded feedback

    fetchData(
      `https://zola.technology/api/company/feedback/issues/resolved/list/?filter=${timeRange}&page=1&page_size=1`,
      (data) => setRespondedTotal(data.total)
    );

    fetchData(
      `https://zola.technology/api/company/feedback/issues/non-resolved/list/?filter=${timeRange}&page=1&page_size=1`,
      (data) => setNonRespondedTotal(data.total)
    );

    // Fetch data for the active tab
    const url =
      activeTab === "responded"
        ? `https://zola.technology/api/company/feedback/issues/resolved/list/?page=${pagination.page}&page_size=${pagination.pageSize}&filter=${timeRange}`
        : `https://zola.technology/api/company/feedback/issues/non-resolved/list/?page=${pagination.page}&page_size=${pagination.pageSize}&filter=${timeRange}`;

    Promise.all([
      fetchData(
        `https://zola.technology/api/company/feedback/get-filtered-feedback/?filter=${timeRange}`,
        setFilteredFeedback1
      ),
      fetchData(
        `https://zola.technology/api/company/feedback/get/filtered-categorized/?filter=${timeRange}`,
        setCategorizedFeedback
      ),
      fetchData(
        url,
        activeTab === "responded"
          ? setResolvedFeedbackData
          : setNonResolvedFeedback
      ),
    ]).finally(() => setLoading(false));
  }, [timeRange, pagination, activeTab]);

  // Calculate grand totals
  const totalFeedback = categorizedFeedback.total_feedback;

  const posPercent = categorizedFeedback.positive?.percentage;
  const negPercent = categorizedFeedback.negative?.percentage;
  const neutPercent = categorizedFeedback.neutral?.percentage;

  const renderStars = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <FaStar key={index} className="text-yellow-500" />
    ));
  };

  // Safely filter resolved and unresolved feedback
  const resolvedFeedback =
    filteredFeedback1[0]?.feedback?.filter(
      (feedback) => feedback.resolved === true
    ) || [];
  const unresolvedFeedback =
    filteredFeedback1[0]?.feedback?.filter(
      (feedback) => feedback.resolved === false
    ) || [];

  // Display based on active tab
  const displayedFeedback =
    activeTab === "responded" ? resolvedFeedbackData : nonResolvedFeedback;

  // Open Modal with selected feedback details
  const openModal = async (feedback) => {
    setSelectedFeedback(feedback);

    const employeeId = feedback?.employee?.id || null;

    if (employeeId) {
      try {
        // Get token from localStorage
        const token = localStorage.getItem("token");

        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/ratings/categorized-totals/${employeeId}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Add token to the Authorization header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch rating data");
        }

        const ratingData = await response.json();

        // Update the rating section in the modal
        setRatingData(ratingData);
      } catch (error) {
        console.error("Error fetching rating data:", error);
      }
    } else {
      // Provide fallback rating data
      setRatingData({
        rating_of_1_count: 0,
        rating_of_2_count: 0,
        rating_of_3_count: 0,
        rating_of_4_count: 0,
        rating_of_5_count: 0,
      });
    }

    setShowModal(true);
  };

  // Close Modal
  const closeModal = () => {
    setSelectedFeedback(null);
    setShowModal(false);
  };

  // Function to get initials from a full name
  const getInitials = (name) => {
    // Check if the name is a valid string
    if (typeof name !== "string" || !name) return "";

    const nameParts = name.trim().split(" ");

    // If there's only one word, return the first letter of that word
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    }

    // If there are multiple words, take the first letter of the first two words
    const initials = nameParts[0][0] + nameParts[1][0];

    return initials.toUpperCase();
  };

  console.log(resolvedFeedbackData?.data ?? [], "selected feed");

  const maxCount = Math.max(
    ratingData.rating_of_5_count,
    ratingData.rating_of_4_count,
    ratingData.rating_of_3_count,
    ratingData.rating_of_2_count,
    ratingData.rating_of_1_count
  );

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleResolveFeedback = async (feedbackId) => {
    if (!feedbackId) return;

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `https://zola.technology/api/company/feedback/update-feedback-to-resolved/?feedback_id=${feedbackId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            resolved: true, // Send resolved status
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to resolve feedback.");
      }

      toast.success("Feedback marked as resolved!");

      // Update the UI: Remove from non-resolved and add to resolved list
      setNonResolvedFeedback((prev) =>
        prev.data.filter((feedback) => feedback.id !== feedbackId)
      );

      setResolvedFeedbackData((prev) => ({
        ...prev,
        data: [...prev.data, { ...selectedFeedback, resolved: true }],
      }));

      setShowModal(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="md:p-2 min-h-screen">
      <ToastContainer />
      {isExpired && <Subscription />}
      <Overview title="Feedback" showExportButton={false} />

      <div className="bg-white dark:bg-gray-800 rounded-lg h-auto p-4 shadow-md">
        <div className="w-full grid grid-cols-12 px-6 pt-2">
          {/* Total Feedback */}
          <div className="col-span-12 lg:col-span-3 text-[28px] font-semibold text-gray-900 dark:text-gray-100">
            <p className="text-gray-700 dark:text-gray-400 text-[16px] font-medium">
              Total Feedback
            </p>
            <p className="text-gray-900 dark:text-gray-100 text-[36px] font-semibold">
              {totalFeedback}
            </p>
          </div>

          {/* Feedback Sentiments */}
          <div className="col-span-12 lg:col-span-9 flex flex-col gap-4">
            <div className="flex flex-col md:flex-row justify-between mb-4 gap-4">
              {/* Positive Feedback */}
              <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
                <div className="flex items-center gap-2">
                  <PiThumbsUpFill className="text-green-500 dark:text-green-300 w-[28px] h-[25px]" />
                  <p className="text-gray-600 dark:text-gray-400 text-[14px] font-medium">
                    Positive
                    <p className="text-[20px] font-bold text-gray-900 dark:text-gray-100">
                      {posPercent}%
                    </p>
                  </p>
                </div>
                <div className="w-11/12 bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-green-500 dark:bg-green-400 h-2 rounded-full"
                    style={{ width: `${posPercent}%` }}
                  ></div>
                </div>
              </div>

              {/* Negative Feedback */}
              <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
                <div className="flex items-center gap-2">
                  <PiThumbsDownFill className="text-red-500 dark:text-red-300 w-[28px] h-[25px]" />
                  <p className="text-gray-600 dark:text-gray-400 text-[14px] font-medium">
                    Negative
                    <p className="text-[20px] font-bold text-gray-900 dark:text-gray-100">
                      {negPercent}%
                    </p>
                  </p>
                </div>
                <div className="w-11/12 bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-red-500 dark:bg-red-400 h-2 rounded-full"
                    style={{ width: `${negPercent}%` }}
                  ></div>
                </div>
              </div>

              {/* Neutral Feedback */}
              <div className="flex flex-col gap-2 items-start w-full md:w-2/3">
                <div className="flex items-center gap-2">
                  <FaHandFist className="text-yellow-500 dark:text-yellow-400 w-[32px] h-[32px]" />
                  <p className="text-gray-600 dark:text-gray-400 text-[14px] font-medium">
                    Neutral
                    <p className="text-[20px] font-bold text-gray-900 dark:text-gray-100">
                      {neutPercent}%
                    </p>
                  </p>
                </div>
                <div className="w-11/12 bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-yellow-500 dark:bg-yellow-400 h-2 rounded-full"
                    style={{ width: `${neutPercent}%` }}
                  ></div>
                </div>
              </div>
            </div>

            {/* <div className="flex flex-col md:flex-row gap-28">
              <div className="flex items-center px-4 py-2 text-blue-600 dark:text-blue-400 font-medium">
                <div className="w-4 h-4 bg-blue-600 dark:bg-blue-500 rounded-full flex items-center justify-center text-white mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                Responded:{" "}
                {resolvedFeedback.length > 0
                  ? resolvedFeedback.length
                  : "No responses yet"}
              </div>
              <div className="flex items-center px-4 py-2 text-red-600 dark:text-red-400 font-medium">
                <div className="w-4 h-4 bg-red-600 dark:bg-red-500 rounded-full flex items-center justify-center text-white mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                No Response:{" "}
                {unresolvedFeedback.length > 0
                  ? unresolvedFeedback.length
                  : "No pending responses"}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="flex flex-col h-auto">
        <div className="sticky my-6 w-full">
          <div className="flex items-center rounded-full border border-full border-gray-300 dark:border-gray-600 w-full h-[48px]">
            <button
              aria-label="Responded"
              className={`w-1/2 px-6 py-3 text-xs md:text-base rounded-full font-medium focus:outline-none transition-all duration-200 ${
                activeTab === "responded"
                  ? "bg-white dark:bg-gray-900 text-blue-600 dark:text-blue-400 h-[48px]"
                  : "text-gray-600 dark:text-gray-400 bg-[#F5F8FF] dark:bg-gray-700"
              }`}
              style={{
                boxShadow:
                  activeTab === "responded"
                    ? "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
                    : "none",
              }}
              onClick={() => setActiveTab("responded")}
            >
              <span>Responded</span>
              <span
                className={`ml-2 w-6 h-6 inline-flex items-center justify-center text-xs font-semibold ${
                  activeTab === "responded"
                    ? "bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400"
                    : "bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-400"
                } px-2 py-1 rounded-full`}
              >
                {respondedTotal}
              </span>
            </button>

            <button
              aria-label="No Response"
              className={`w-1/2 px-6 py-3 text-xs md:text-base rounded-full font-medium focus:outline-none transition-all duration-200 ${
                activeTab === "no-response"
                  ? "bg-white dark:bg-gray-900 text-red-600 dark:text-red-400 h-[48px]"
                  : "text-gray-600 dark:text-gray-400 bg-[#F5F8FF] dark:bg-gray-700"
              }`}
              style={{
                boxShadow:
                  activeTab === "no-response"
                    ? "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814"
                    : "none",
              }}
              onClick={() => setActiveTab("no-response")}
            >
              <span>Action Needed</span>
              <span
                className={`ml-2 w-6 h-6 inline-flex items-center justify-center text-xs font-semibold ${
                  activeTab === "no-response"
                    ? "bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-400"
                    : "bg-gray-200 dark:bg-gray-700 text-gray-600 dark:text-gray-400"
                } p-2 rounded-full`}
              >
                {nonRespondedTotal}
              </span>
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto no-scrollbar">
          {displayedFeedback?.data?.length > 0 ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-0">
              {displayedFeedback.data.map((feedback, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-gray-800 p-4 rounded-[12px] hover:scale-95 transition"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-medium text-[20px] text-gray-900 dark:text-gray-100">
                        {feedback.author_name}
                      </h3>
                      <span className="text-gray-600 dark:text-gray-400 text-[14px]">
                        {new Date(feedback.created_at).toLocaleDateString()}
                      </span>
                    </div>
                    <button
                      aria-label="Details"
                      className="px-4 py-2 bg-[#0C4EF8] dark:bg-blue-500 rounded-[4px] text-white font-semibold text-[14px] hover:shadow-lg transition"
                      onClick={() => openModal(feedback)}
                    >
                      Details
                    </button>
                  </div>
                  <p className="my-2 text-gray-600 dark:text-gray-400 font-normal text-[16px]">
                    {feedback.text_info}
                  </p>
                  <div className="flex items-center space-x-1">
                    <span className="text-gray-700 dark:text-gray-300 font-medium">
                      {feedback.rating}
                    </span>
                    <FaStar className="text-yellow-500" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-gray-500 dark:text-gray-400">
                No feedback available.
              </p>
            </div>
          )}

          {/* Pagination */}
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <button
                className={`px-4 py-2 rounded-[4px] transition ${
                  pagination.page === 1
                    ? "bg-gray-200 dark:bg-gray-800 text-gray-500 cursor-not-allowed"
                    : "bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500"
                }`}
                disabled={pagination.page === 1}
                onClick={() => handlePageChange(pagination.page - 1)}
              >
                Previous
              </button>

              <span className="text-gray-700 dark:text-gray-300 font-medium">
                Page {pagination.page} of {displayedFeedback?.total_pages || 0}
              </span>

              <button
                className={`px-4 py-2 rounded-[4px] transition ${
                  pagination.page === displayedFeedback?.total_pages
                    ? "bg-gray-200 dark:bg-gray-800 text-gray-500 cursor-not-allowed"
                    : "bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500"
                }`}
                disabled={pagination.page === displayedFeedback?.total_pages}
                onClick={() => handlePageChange(pagination.page + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && selectedFeedback && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-2xl p-6 shadow-2xl relative">
            <div className="flex justify-between items-center mb-4">
              {/* Modal Header */}
              <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                Review Details
              </h2>

              {/* Close Button */}
              <button
                aria-label="Close Modal"
                onClick={closeModal}
                className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition duration-200"
              >
                <MdClose size={24} />
              </button>
            </div>

            <div className="space-y-4">
              <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="border border-gray-300 dark:border-gray-600 p-2 rounded-lg shadow-sm w-full md:w-1/2 lg:w-1/3">
                  {/* Employee Label */}
                  <p className="text-xs font-semibold text-gray-500 dark:text-gray-400 mb-1">
                    Employee
                  </p>

                  {/* Employee Name */}
                  <p className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-2">
                    {selectedFeedback?.employee?.name
                      ? selectedFeedback.employee.name
                      : "Employee Name Not Available"}
                  </p>

                  {/* Taps and Rating */}
                  <div className="flex items-center text-gray-600 dark:text-gray-400">
                    <span className="text-lg font-medium">
                      {selectedFeedback?.employee?.number_of_taps ?? "N/A"}
                    </span>
                    <span className="text-xs ml-1">taps</span>
                    <span className="ml-3 text-lg font-medium flex items-center gap-1">
                      {selectedFeedback?.employee_rating || "N/A"}
                      <FaStar className="text-yellow-500" />
                    </span>
                  </div>
                </div>

                {/* Rating Distribution */}
                <div className="w-full md:w-1/2 rounded-lg">
                  <div className="flex flex-col gap-2">
                    {[
                      {
                        rating: "5",
                        count: ratingData.rating_of_5_count,
                        color: "bg-green-400",
                      },
                      {
                        rating: "4",
                        count: ratingData.rating_of_4_count,
                        color: "bg-purple-400",
                      },
                      {
                        rating: "3",
                        count: ratingData.rating_of_3_count,
                        color: "bg-yellow-400",
                      },
                      {
                        rating: "2",
                        count: ratingData.rating_of_2_count,
                        color: "bg-orange-400",
                      },
                      {
                        rating: "1",
                        count: ratingData.rating_of_1_count,
                        color: "bg-red-400",
                      },
                    ].map((level, index) => (
                      <div key={index} className="flex items-center">
                        <FaStar fill="#eab308" />
                        <span className="text-sm text-gray-600 dark:text-gray-400 ml-2">
                          {level.rating}
                        </span>
                        <div className="ml-2 flex-1">
                          <div
                            className={`h-2 rounded-full ${
                              level.count > 0
                                ? level.color
                                : "bg-gray-200 dark:bg-gray-700"
                            }`}
                            style={{
                              width:
                                level.count > 0
                                  ? `${(level.count / maxCount) * 100}%`
                                  : "100%",
                            }}
                          ></div>
                        </div>
                        <span className="text-sm text-gray-600 dark:text-gray-400 ml-2 flex-shrink-0">
                          {level.count > 0 ? level.count.toLocaleString() : "0"}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Reviewer Section */}
              <div className="rounded-lg border border-gray-300 dark:border-gray-600">
                <div className="px-4 py-2">
                  <div className="flex items-start justify-between">
                    <div>
                      <p className="text-sm font-semibold text-gray-500 dark:text-gray-400">
                        Reviewer
                      </p>
                      <p className="text-lg font-bold text-gray-900 dark:text-gray-100">
                        {selectedFeedback?.author_name || "Anonymous"}
                      </p>
                      {selectedFeedback?.phone_number && (
                        <p className="text-gray-500 dark:text-gray-400 flex items-center gap-2">
                          <FaPhoneAlt className="text-gray-400 dark:text-gray-500" />
                          {selectedFeedback.phone_number}
                        </p>
                      )}
                    </div>
                    <p className="text-sm text-gray-400 dark:text-gray-500">
                      {new Date(
                        selectedFeedback?.created_at
                      ).toLocaleDateString()}
                    </p>
                  </div>
                  <hr className="mt-4 border-gray-300 dark:border-gray-600" />
                </div>

                {/* Review Text Section */}
                <div className="px-4 py-2">
                  <p className="text-gray-700 dark:text-gray-300 text-sm">
                    {selectedFeedback?.text_info ||
                      "No additional information provided."}
                  </p>
                  <span className="text-lg dark:text-gray-300 font-medium flex items-center gap-1">
                    {selectedFeedback?.rating || "N/A"}
                    <FaStar className="text-yellow-500" />
                  </span>
                </div>
              </div>

              {/* Resolve Feedback Button */}
              {!selectedFeedback?.resolved && (
                <button
                  onClick={() => handleResolveFeedback(selectedFeedback?.id)}
                  className="bg-green-500 dark:bg-green-600 text-white font-medium px-4 py-2 rounded-lg hover:bg-green-600 dark:hover:bg-green-700 transition-all duration-300"
                >
                  Mark as Resolved
                </button>
              )}

              {/* Resolved Status */}
              <div className="flex items-center gap-3 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
                {selectedFeedback?.resolved ? (
                  <FaCheckCircle className="text-green-500 text-lg" />
                ) : (
                  <FaTimesCircle className="text-red-500 text-lg" />
                )}
                <p className="text-gray-700 dark:text-gray-300">
                  <span className="font-semibold">Resolved:</span>{" "}
                  {selectedFeedback?.resolved ? "Yes" : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Feedback;
