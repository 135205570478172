import { FaDownload } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useTimeRange } from "../../context/TimeRangeContext";
import { useState } from "react";

function Overview({
  title = "Overview",
  showExportButton = true,
  onExportClick = null, // Accept a callback for the export button
}) {
  const { timeRange, setTimeRange } = useTimeRange();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Manage dropdown visibility

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setTimeRange(selectedValue);
    localStorage.setItem("filter", selectedValue);

    console.log(`Time Range changed to: ${selectedValue}`);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="flex flex-col lg:flex-row gap-4 items-start lg:items-center justify-between py-6">
      {/* Left Section */}
      <div className="text-start">
        <h1 className="text-2xl font-medium text-gray-900 dark:text-gray-100">
          {title}
        </h1>
        {/* <p className="text-gray-500 dark:text-gray-400">
          There is the latest update for this {timeRange.toLowerCase()}. Check now.
        </p> */}
      </div>

      {/* Right Section */}
      <div className="flex items-center gap-4">
        {/* Dropdown for Time Range */}
        <div className="relative inline-block w-32">
          <select
            value={timeRange}
            onChange={handleSelectChange}
            className="appearance-none bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg px-4 py-2 pr-10 text-gray-700 dark:text-gray-100 font-medium focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 w-full"
          >
            {/* <option value="">all time</option> */}
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
          <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
            <IoIosArrowDown className="text-gray-500 dark:text-gray-400 text-sm" />
          </div>
        </div>

        {/* Export Button with Dropdown */}
        {showExportButton && (
          <div className="relative">
            <button
              onClick={toggleDropdown}
              className="bg-blue-600 dark:bg-blue-700 text-white px-6 py-2 rounded-md flex items-center hover:bg-blue-700 dark:hover:bg-blue-800 transition"
            >
              <FaDownload className="mr-2" /> Export
              <IoIosArrowDown className="ml-2" />
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-lg w-40 z-10">
                <button
                  className="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-gray-100"
                  onClick={() => {
                    onExportClick("pdf");
                    setIsDropdownOpen(false);
                  }}
                >
                  Export as PDF
                </button>
                <button
                  className="block px-4 py-2 w-full text-left hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-900 dark:text-gray-100"
                  onClick={() => {
                    onExportClick("image");
                    setIsDropdownOpen(false);
                  }}
                >
                  Export as Image
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Overview;
