import React, { useState, useEffect, useRef } from "react";
import { FaPaperPlane, FaMicrophone } from "react-icons/fa";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png";
import { FaRecordVinyl } from "react-icons/fa6";

function AIChat() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [chatStarted, setChatStarted] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const chatContainerRef = useRef(null);

  const handleSend = () => {
    if (inputValue.trim()) {
      if (!chatStarted) setChatStarted(true);
      
      const newMessage = { text: inputValue, sender: "user" };
      setMessages((prev) => [...prev, newMessage]);

      setIsGenerating(true);
      setTimeout(() => generateAIResponse(inputValue), 1500);

      setInputValue("");
    }
  };

  const generateAIResponse = (userInput) => {
    const aiResponse = `Here is my response to: "${userInput}"`;

    let index = 0;
    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage?.sender === "ai") {
          return [
            ...prevMessages.slice(0, -1),
            { text: lastMessage.text + aiResponse[index], sender: "ai" },
          ];
        } else {
          return [...prevMessages, { text: aiResponse[index], sender: "ai" }];
        }
      });
      index++;

      if (index >= aiResponse.length) {
        clearInterval(interval);
        setIsGenerating(false);
      }
    }, 50);
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
<div className="flex flex-col justify-between h-screen bg-gray-50 dark:bg-gray-900 p-4 md:p-6">
  {/* Header */}
  <div className="w-full">
    <h1 className="text-xl md:text-2xl font-semibold text-gray-800 dark:text-gray-100">
      New Request
    </h1>
    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
      Select from our suggestions or type your problem
    </p>
  </div>

  {/* Chat Messages */}
  <div
    ref={chatContainerRef}
    className="flex-grow items-center justify-center overflow-y-auto p-4 space-y-4 mx-auto w-full max-w-4xl"
  >
    {!chatStarted && (
      <div className="flex flex-col items-center justify-center w-full">
        <img
          src={ZolaLogo}
          alt="Zola Logo"
          className="h-10 w-32 md:h-12 md:w-48 mb-4 object-contain"
        />
        <h2 className="text-lg md:text-xl font-bold text-gray-800 dark:text-gray-100">
          Hi, <span className="text-blue-600">Zola.AI</span> here
        </h2>
        <p className="text-xs md:text-sm text-gray-500 dark:text-gray-400 mt-2">
          Choose from my selected prompts
        </p>

        <div className="w-full max-w-lg md:max-w-2xl bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 md:p-6 mt-6 md:mt-8">
          <h3 className="text-xs md:text-sm font-medium text-gray-600 dark:text-gray-300 flex items-center mb-4">
            <span className="w-5 h-5 md:w-6 md:h-6 bg-blue-100 text-blue-600 dark:bg-blue-800 dark:text-blue-200 rounded-full flex items-center justify-center mr-2">
              ⓘ
            </span>
            Problems
          </h3>

          <div className="space-y-3 md:space-y-4">
            <button
              onClick={() => {
                setInputValue("Please write four questions about my restaurant");
                setChatStarted(true);
                handleSend();
              }}
              className="w-full text-left px-3 py-2 md:px-4 md:py-3 border rounded-lg text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition"
            >
              Please write Survey questions about Restaurant
            </button>
            <button
              onClick={() => {
                setInputValue("Please write four questions about my clinic");
                setChatStarted(true);
                handleSend();
              }}
              className="w-full text-left px-3 py-2 md:px-4 md:py-3 border rounded-lg text-gray-700 dark:text-gray-200 bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 transition"
            >
              Please write Survey questions about Clinic
            </button>
          </div>
        </div>
      </div>
    )}

    {chatStarted &&
      messages.map((message, index) => (
        <div
          key={index}
          className={`flex ${
            message.sender === "user" ? "justify-end" : "justify-start"
          }`}
        >
          <div
            className={`px-3 py-2 md:px-4 md:py-2.5 rounded-lg max-w-[80%] md:max-w-sm ${
              message.sender === "user"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
            }`}
          >
            {message.text}
          </div>
        </div>
      ))}
  </div>

  {/* Fixed Footer Input */}
  <div className="fixed bottom-0 left-0 md:left-64 right-0 bg-white dark:bg-gray-800 shadow-md p-3 md:p-4">
    <div className="max-w-lg md:max-w-2xl mx-auto flex items-center border rounded-lg bg-gray-50 dark:bg-gray-700 shadow-md p-2 md:p-3">
      <input
        type="text"
        placeholder="Write here..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSend();
          }
        }}
        className="flex-grow border-none bg-transparent outline-none text-gray-600 dark:text-gray-200 placeholder-gray-400 dark:placeholder-gray-500 text-sm md:text-base"
      />
      <button
        onClick={handleSend}
        disabled={isGenerating}
        className="text-gray-500 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400 transition ml-2"
      >
        {isGenerating ? (
          <FaRecordVinyl
            className="animate-pulse text-blue-500 dark:text-blue-400"
            size={18}
          />
        ) : (
          <FaPaperPlane size={18} />
        )}
      </button>
    </div>
  </div>
</div>

  );
}

export default AIChat;
