import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaStar, FaExclamationTriangle } from "react-icons/fa";
import { PiCopyBold, PiFunnelBold } from "react-icons/pi";
import EditForm from "./EditForm"; // Assuming you have an EditForm component
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";

function CompanyEmployeeTable({ refreshTable, onDeleteSuccess }) {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", username: "" });
  const [sortOption, setSortOption] = useState("Sort by");
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [totalTablePages, setTotalTablePages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const tablePageSize = itemsPerPage;

  // Fetch paginated employee table data
  const fetchTableData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/get-all/?page=${currentTablePage}&page_size=${tablePageSize}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch table data");

      const result = await response.json();
      setTableData(result.data);
      setTotalTablePages(result.total_pages);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Call fetch function in useEffect

  useEffect(() => {
    fetchTableData();
  }, [currentTablePage, tablePageSize]);

  useEffect(() => {
    fetchTableData();
  }, [refreshTable, currentTablePage]);

  const handleModalToggle = () => {
    if (isModalOpen) setFormData({ name: "", username: "" });
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    if (!formData.name.trim()) {
      toast.error("Name cannot be empty.");
      return;
    }
    if (!formData.username.trim()) {
      toast.error("Username cannot be empty.");
      return;
    }

    try {
      const response = await fetch(
        "https://zola.technology/api/company/employees/add/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.username?.[0] || "Error adding employee");
        throw new Error("Network response was not ok");
      }
      toast.success("Employee added successfully");
      setIsModalOpen(false);
      setFormData({ name: "", username: "" });
      fetchTableData(); // Refresh the data after adding
    } catch (error) {
      toast.error("Error adding employee");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelect = (option) => {
    setSortOption(option);
    setIsOpen(false);
    const sortedData = [...tableData];
    switch (option) {
      case "Rating":
        sortedData.sort((a, b) => b.average_rating - a.average_rating);
        break;
      case "Name":
        sortedData.sort((a, b) =>
          a.employee.name.localeCompare(b.employee.name)
        );
        break;
      case "Feedbacks":
        sortedData.sort((a, b) => b.total_feedback - a.total_feedback);
        break;
      case "Rewards":
        sortedData.sort((a, b) => b.reward_total - a.reward_total);
        break;
      default:
        break;
    }
    setTableData(sortedData);
  };

  const handleDeleteConfirm = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("No token found");
      return;
    }

    setLoading(true);
    setDeletingIndex(selectedEmployee?.id);

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/delete/${selectedEmployee?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to delete employee");

      setTableData((prev) =>
        prev.filter((item) => item.employee.id !== selectedEmployee?.id)
      );
      toast.success(`${selectedEmployee?.name} deleted successfully!`);
      onDeleteSuccess(); // 🔥 Trigger fetchData() in parent
    } catch (error) {
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
      setIsDeleteModalOpen(false);
      setSelectedEmployee(null);
    }
  };

  const handleUpdate = (item) => {
    setShowPopup(true);
    setSelectedItemIndex(item);
  };

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${selectedItemIndex.employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      setTableData((prev) =>
        prev.map((item) =>
          item.employee.id === selectedItemIndex.employee.id
            ? { ...item, employee: updatedValues }
            : item
        )
      );
      toast.success("Employee data updated successfully!");
      fetchTableData();
    } catch (error) {
      toast.error(`Update Error: ${error.message}`);
    } finally {
      setShowPopup(false);
      setSelectedItemIndex(null);
    }
  };

  const handleDeleteClick = (item) => {
    setSelectedEmployee(item.employee);
    setIsDeleteModalOpen(true);
  };

  const filteredTableData = tableData.filter((item) =>
    item.employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTablePageChange = (direction) => {
    setCurrentTablePage((prevPage) =>
      direction === "next" ? prevPage + 1 : prevPage - 1
    );
  };

  return (
    <div className="w-full">
      <div className="bg-white dark:bg-gray-800 p-4 flex flex-col lg:flex-row items-center w-full rounded-t-[8px]">
        <h3 className="text-[24px] font-semibold text-center lg:text-start text-[#4C4C4C] dark:text-gray-100 w-full">
          Employees
        </h3>
        <div className="flex flex-col lg:flex-row gap-4 items-center justify-between md:w-10/12 sm:w-11/12 xs:w-full">
          {/* Sort Dropdown */}
          <div className="relative flex items-center w-full md:w-3/12">
            <button
              aria-label="sort"
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center font-medium py-2 px-4 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600 transition"
            >
              <PiFunnelBold className="text-lg" />
              <span className="ml-2">Sort by</span>
              {isOpen ? (
                <IoCaretUpOutline className="ml-auto" />
              ) : (
                <IoCaretDownOutline className="ml-auto" />
              )}
            </button>
            {isOpen && (
              <div className="absolute z-10 mt-2 w-full md:w-48 rounded-lg shadow-lg bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600">
                <ul className="py-1 text-sm text-gray-700 dark:text-gray-300">
                  {["Rating", "Name", "Rewards", "Feedbacks"].map((option) => (
                    <li
                      key={option}
                      onClick={() => handleSelect(option)}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Search Bar */}
          <div className="flex items-center gap-2">
            <div className="relative border flex items-center py-2 px-3 rounded-lg bg-gray-100 dark:bg-gray-700 w-7/12">
              <HiOutlineMagnifyingGlass className="text-gray-600 dark:text-gray-300 w-5 h-5" />
              <input
                type="text"
                placeholder="Search..."
                className="ml-2 w-full bg-transparent text-gray-800 dark:text-gray-200 focus:outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <button
              aria-label="clear search"
              className="bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200 font-semibold px-4 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-500 transition"
              onClick={() => setSearchTerm("")}
            >
              Clear
            </button>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-separate border-spacing-y-3">
          <thead>
            <tr className="text-left text-[#6F707E] text-[16px] font-medium">
              <th className="px-4 py-2 font-normal">S/N</th>
              <th className="px-4 py-2 font-normal">Name</th>
              <th className="px-4 py-2 font-normal">Card URL</th>
              <th className="px-4 py-2 font-normal">Feedback</th>
              <th className="px-4 py-2 font-normal">Rewards</th>
              <th className="px-4 py-2 font-normal">Rating</th>
              <th className="px-4 py-2 font-normal">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredTableData.map((item, index) => (
              <tr
                key={index}
                className="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 transition"
              >
                <td data-cell="S/N" className="md:px-2 py-2 ">
                  {index + 1}
                </td>
                <td data-cell="Name" className="px-4 py-2 ">
                  <div className="flex items-center space-x-4">
                    <div
                      className={`p-2 rounded-full bg-blue-600 flex items-center justify-center 
                 ${item.employee.name.includes(" ") ? "w-10" : "w-9"}`}
                    >
                      <p className="text-white font-semibold text-[12px]">
                        {item.employee.name
                          .split(" ")
                          .slice(0, 2)
                          .map((word) => word[0].toUpperCase())
                          .join("")}
                      </p>
                    </div>

                    <span className="text-[16px] font-semibold">
                      {item.employee.name}
                    </span>
                  </div>
                </td>
                <td data-cell="card URL" className="px-2 py-2 ">
                  <div className="nfc-url flex  items-center gap-3">
                    <a
                      href={item.employee.employee_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" w-48 text-[14px] break-words italic font-medium"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                      }}
                    >
                      {item.employee.employee_url}
                    </a>
                    <button
                      aria-label="copy"
                      className="text-[#0C4EF8]"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          item.employee.employee_url
                        );
                        toast.success("Employee URL copied to clipboard!");
                      }}
                    >
                      <PiCopyBold className=" w-4 h-4" />
                    </button>
                  </div>
                </td>
                <td
                  data-cell="Feedback"
                  className="px-4 py-2 font-semibold text-[16px]"
                >
                  {item.total_feedback}
                </td>
                <td
                  data-cell="Rewards"
                  className="px-4 py-2 font-semibold text-[16px]"
                >
                  {item.reward_total}
                </td>
                <td
                  data-cell="Rating"
                  className="px-4 py-2 font-semibold text-[16px]"
                >
                  <div className="flex items-center gap-2">
                    <p>{item.average_rating}</p>
                    <FaStar className="text-yellow-500" />
                  </div>
                </td>
                <td data-cell="Action" className="md:px-8 py-2   ">
                  <div className="space-x-2 flex flex-row">
                    <button
                      aria-label="update"
                      className=" py-1 bg-[#0C4EF8] font-semibold text-[#FEFEFF] text-[14px] rounded-[4px] hover:bg-blue-600 transition xs:w-5/12 md:w-6/12 flex justify-center items-center"
                      onClick={() => handleUpdate(item)}
                    >
                      Update
                    </button>
                    <button
                      aria-label="delete"
                      onClick={() => handleDeleteClick(item)} // Opens delete confirmation modal
                      className=" py-1 bg-[#FDEAEB] font-semibold text-[#E6283B] rounded-[4px]  hover:bg-red-600 transition xs:w-5/12 md:w-6/12"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          {/* Left Section: Showing Entries */}
          <div className="text-sm text-gray-500">
            Showing {(currentTablePage - 1) * itemsPerPage + 1} to{" "}
            {Math.min(currentTablePage * itemsPerPage, tableData.length)} of{" "}
            {tableData.length} entries
          </div>

          {/* Center Section: Pagination */}
          <div className="flex items-center space-x-2">
            <button
              onClick={() =>
                setCurrentTablePage((prev) => Math.max(prev - 1, 1))
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
            >
              &lt;
            </button>

            {currentTablePage > 3 && (
              <>
                <button
                  onClick={() => setCurrentTablePage(1)}
                  className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
                >
                  1
                </button>
                <span className="text-gray-600">...</span>
              </>
            )}

            {Array.from(
              { length: Math.min(3, totalTablePages) },
              (_, i) => currentTablePage - 1 + i
            )
              .filter((page) => page > 0 && page <= totalTablePages)
              .map((page) => (
                <button
                  key={page}
                  onClick={() => setCurrentTablePage(page)}
                  className={`flex items-center justify-center w-8 h-8 ${
                    currentTablePage === page
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 text-gray-600"
                  } rounded-md hover:bg-gray-200 transition`}
                >
                  {page}
                </button>
              ))}

            {currentTablePage < totalTablePages - 2 && (
              <>
                <span className="text-gray-600">...</span>
                <button
                  onClick={() => setCurrentTablePage(totalTablePages)}
                  className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
                >
                  {totalTablePages}
                </button>
              </>
            )}

            <button
              onClick={() =>
                setCurrentTablePage((prev) =>
                  Math.min(prev + 1, totalTablePages)
                )
              }
              className="flex items-center justify-center w-8 h-8 bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200 transition"
            >
              &gt;
            </button>
          </div>

          {/* Right Section: Entries Dropdown */}
          <div className="flex items-center">
            <span className="text-sm text-gray-500 mr-2">Show</span>
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              className="border border-gray-300 text-sm text-gray-600 rounded-md focus:ring-blue-500 focus:border-blue-500 transition"
            >
              {[8, 16, 24, 32].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>

        {showPopup && selectedItemIndex && (
          <EditForm
            selectedItem={selectedItemIndex.employee}
            onUpdate={handleUpdateData}
            onClose={() => setShowPopup(false)}
          />
        )}

        {isDeleteModalOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            role="dialog"
            aria-modal="true"
          >
            {/* Modal Content */}
            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 w-[90%] max-w-sm text-center shadow-lg relative">
              {/* Close Button */}
              <button
                className="absolute top-2 right-2 text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-white"
                onClick={() => setIsDeleteModalOpen(false)}
                aria-label="Close delete confirmation modal"
              >
                ✕
              </button>

              {/* Warning Icon */}
              <FaExclamationTriangle className="text-[#EE6F7C] text-5xl mx-auto mb-4" />

              {/* Modal Title */}
              <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-semibold">
                Delete Employee?
              </h2>

              {/* Warning Message */}
              <p className="text-gray-600 dark:text-gray-300 text-lg">
                This action cannot be undone.
              </p>

              {/* Action Buttons */}
              <div className="flex justify-center space-x-4 mt-6">
                <button
                  className="bg-blue-600 dark:bg-blue-500 text-white font-semibold px-6 py-2 rounded-lg hover:bg-blue-700 dark:hover:bg-blue-400 transition"
                  onClick={() => setIsDeleteModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="border border-red-400 text-red-600 dark:text-red-400 font-semibold px-6 py-2 rounded-lg hover:bg-red-600 hover:text-white transition"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CompanyEmployeeTable;
