import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";

const DeleteConfirmationPopup = ({ isOpen, onClose, onConfirm, item }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
      <div
        className="bg-white dark:bg-gray-800 rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4 shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <FaExclamationTriangle className="text-red-500 dark:text-red-400 text-5xl mx-auto" />
        <h2 className="text-2xl text-gray-900 dark:text-gray-100 font-semibold">
          Delete {item}?
        </h2>
        <p className="text-gray-600 dark:text-gray-300">
          This action cannot be undone. Are you sure?
        </p>
        <div className="flex justify-center space-x-4 mt-4">
          <button
            onClick={onConfirm}
            className="bg-red-600 dark:bg-red-500 text-white font-semibold px-8 py-2 rounded-[4px] hover:bg-red-700 dark:hover:bg-red-400 transition"
          >
            Yes, Delete
          </button>
          <button
            className="border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 font-semibold px-8 py-2 rounded-[4px] hover:bg-gray-200 dark:hover:bg-gray-700 transition"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
