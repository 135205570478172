import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { MdOutlineFrontHand } from "react-icons/md";
import ReviewSection from "./ReviewSection";
import IncentiveSection from "./IncentiveSection";
import { useEffect, useState } from "react";
import axios from "axios";
import { useTimeRange } from "../../../context/TimeRangeContext";
import { FaHandFist } from "react-icons/fa6";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function FeedbackSection() {
  const [feedbackData, setFeedbackData] = useState([]);
  const { timeRange } = useTimeRange();

  const [filter, setFilter] = useState(
    localStorage.getItem("filter") || "week"
  );
  const [totals, setTotals] = useState({
    positive: 0,
    neutral: 0,
    negative: 0,
  });

  // Retrieve company_type from userData in local storage
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const companyType = userData?.company_type;

  const fetchFeedbackData = async (timeRange) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `https://zola.technology/api/company/feedback/list-categorised/filtered/?filter=${timeRange}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      setFeedbackData(data);

      // Calculate totals
      const totalPositive = data.reduce(
        (sum, item) => sum + item.positive_feedback_count,
        0
      );
      const totalNeutral = data.reduce(
        (sum, item) => sum + item.neutral_feedback_count,
        0
      );
      const totalNegative = data.reduce(
        (sum, item) => sum + item.negative_feedback_count,
        0
      );

      setTotals({
        positive: totalPositive,
        neutral: totalNeutral,
        negative: totalNegative,
      });
    } catch (error) {
      console.error("Error fetching feedback data:", error);
    }
  };

  useEffect(() => {
    // Fetch data whenever the filter changes
    fetchFeedbackData(timeRange);
  }, [timeRange]);

  if (!feedbackData || feedbackData.length === 0) {
    return <></>;
  }

  // Format labels based on filter
  const formattedLabels = feedbackData.map((item) => {
    if (timeRange === "year" && item.period) {
      return item.period.split(" ")[0] || "Unknown"; // Extract month (e.g., "Jan")
    } else if (timeRange === "week" && item.period) {
      const dayPart = item.period.split(" ")[0];
      return dayPart || "Unknown"; // Extract day (e.g., "Monday")
    }
    return item.period || "Unknown"; // Default period
  });

  // Stacked bar chart data and options
  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: "Positive",
        data: feedbackData.map((item) => item.positive_feedback_count),
        backgroundColor: "#00c853", // Green
      },
      {
        label: "Neutral",
        data: feedbackData.map((item) => item.neutral_feedback_count),
        backgroundColor: "#ffc400", // Yellow
      },
      {
        label: "Negative",
        data: feedbackData.map((item) => item.negative_feedback_count),
        backgroundColor: "#d50000", // Red
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
      },
    },
  };

  return (
    <div className="flex w-full flex-col lg:flex-row gap-6 mb-2">
      {/* Feedback Chart Section */}
      <div className="w-full lg:w-3/5 flex-1 bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-semibold text-gray-900 dark:text-gray-100 text-xl">
            Feedback
          </h3>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 justify-start gap-6 md:gap-8 mb-6">
          {/* Positive Feedback */}
          <div className="flex items-center gap-4 w-full sm:w-auto">
            <span className="text-green-600 text-3xl sm:text-4xl bg-gray-200 dark:bg-gray-700 text-center rounded-full p-4">
              <FaRegThumbsUp />
            </span>
            <div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Positive
              </p>
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100">
                {totals.positive}
              </h3>
            </div>
          </div>

          {/* Neutral Feedback */}
          <div className="flex items-center gap-4 w-full sm:w-auto">
            <span className="text-yellow-500 text-3xl sm:text-4xl bg-gray-200 dark:bg-gray-700 text-center rounded-full p-4">
              <FaHandFist />
            </span>
            <div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Neutral
              </p>
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100">
                {totals.neutral}
              </h3>
            </div>
          </div>

          {/* Negative Feedback */}
          <div className="flex items-center gap-4 w-full sm:w-auto">
            <span className="text-red-600 text-3xl sm:text-4xl bg-gray-200 dark:bg-gray-700 text-center rounded-full p-4">
              <FaRegThumbsDown />
            </span>
            <div>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Negative
              </p>
              <h3 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100">
                {totals.negative}
              </h3>
            </div>
          </div>
        </div>

        <div className="w-[95%] md:w-full h-[250px] sm:h-[300px] lg:h-[300px]">
          <Bar data={chartData} options={chartOptions} />
        </div>
      </div>

      <div className="grid grid-rows-[1fr_1fr] gap-4 w-full lg:w-2/5">
        <ReviewSection />
        {companyType === "team" && <IncentiveSection />}
      </div>
    </div>
  );
}

export default FeedbackSection;
