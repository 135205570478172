import { useParams, useNavigate } from "react-router-dom";
import React from "react";

// Dummy responses (same as used in SurveyResponseDetails)
const dummyResponses = [
  {
    survey_id: "24",
    id: "101",
    respondent: "John Doe",
    submitted_at: "2024-02-02T09:00:00Z",
    answers: [
      { question: "How satisfied are you with our service?", answer: "Very Satisfied" },
      { question: "Would you recommend us to others?", answer: "Yes" },
    ],
  },
  {
    survey_id: "24",
    id: "102",
    respondent: "Jane Smith",
    submitted_at: "2024-02-03T11:30:00Z",
    answers: [
      { question: "How satisfied are you with our service?", answer: "Neutral" },
      { question: "Would you recommend us to others?", answer: "Maybe" },
    ],
  },
  {
    survey_id: "2",
    id: "103",
    respondent: "Alice Johnson",
    submitted_at: "2024-01-20T14:15:00Z",
    answers: [{ question: "How would you rate the work environment?", answer: "Excellent" }],
  },
];

function SurveySingleResponse() {
  const { responseId } = useParams();
  const navigate = useNavigate();

  // Find the response by ID
  const response = dummyResponses.find((r) => r.id === responseId);

  if (!response) {
    return <div className="p-6">Response not found.</div>;
  }

  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-semibold">Survey Response</h2>
      <p className="text-gray-600">Respondent: {response.respondent}</p>
      <p className="text-gray-600">
        Submitted on: {new Date(response.submitted_at).toLocaleString()}
      </p>

      {/* Response Answers */}
      <h3 className="text-xl mt-6 mb-3">Responses</h3>
      <table className="min-w-full table-auto border-separate border-spacing-y-2">
        <thead>
          <tr>
            <th className="py-3 px-4 border-b text-left">Question</th>
            <th className="py-3 px-4 border-b text-left">Answer</th>
          </tr>
        </thead>
        <tbody>
          {response.answers.map((ans, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="py-3 px-4 border-b">{ans.question}</td>
              <td className="py-3 px-4 border-b">{ans.answer}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Back Button */}
      <div className="mt-6">
        <button
          onClick={() => navigate(-1)}
          className="px-6 py-2 bg-gray-600 text-white rounded-md shadow hover:bg-gray-700 transition"
        >
          Back to Survey Responses
        </button>
      </div>
    </div>
  );
}

export default SurveySingleResponse;
