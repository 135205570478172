import React, { useEffect, useState, useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  FaStar,
  FaUserPlus,
  FaSort,
  FaEllipsisH,
  FaCopy,
  FaSearch,
  FaClipboard,
  FaTimes,
  FaExclamationTriangle,
} from "react-icons/fa";
import { MdError, MdVerified } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { PiFunnel } from "react-icons/pi";
import { IoCaretDownOutline, IoCaretUpOutline } from "react-icons/io5";
import EditForm from "./EditForm";
import { useNavigate, useOutletContext } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { PiCopyBold } from "react-icons/pi";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { TiArrowSortedDown } from "react-icons/ti";
import { useMediaQuery } from "react-responsive";
import { PiFunnelBold } from "react-icons/pi";
import QRCode from "qrcode";
import CompanyEmployeeTable from "./CompanyEmployeeTable";
import Subscription from "./Subscription";

function CompanyEmployees() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", username: "" });
  const [sortOption, setSortOption] = useState("Sort by");
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  // Pagination states for cards
  const [currentCardPage, setCurrentCardPage] = useState(1);
  const [totalCardPages, setTotalCardPages] = useState(1);
  const cardsPerPage = 4;

  // Pagination states for table
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [totalTablePages, setTotalTablePages] = useState(1);
  const tablePageSize = 5;
  const [isFetching, setIsFetching] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });
  const [refreshTable, setRefreshTable] = useState(false);
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px)",
  });
  const { isExpired } = useOutletContext();

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/get-all/?page=${currentCardPage}&page_size=${cardsPerPage}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const result = await response.json();
      setData(result.data);
      setTotalCardPages(result.total_pages);
    } catch (error) {
      setError(error.message);
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  };

  const fetchTableData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/get-all/?page=${currentTablePage}&page_size=${tablePageSize}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to fetch table data");

      const result = await response.json();
      setTableData(result.data);
      setTotalTablePages(result.total_pages);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Call fetch functions in useEffect
  useEffect(() => {
    fetchData();
  }, [currentCardPage]);

  useEffect(() => {
    fetchTableData();
  }, [currentTablePage]);

  const labels = data.map((item) => item.employee?.name || "");
  const chartdata = data.map((item) => item.employee?.number_of_taps || 0);

  const handleTableRefresh = () => {
    setRefreshTable((prev) => !prev); // Toggle state to trigger useEffect in Table component
    fetchData();
  };

  // Function to toggle the modal visibility and clear form data when closed
  const handleModalToggle = () => {
    if (isModalOpen) setFormData({ name: "", username: "" }); // Reset form data when closing
    setIsModalOpen(!isModalOpen);
  };

  // form submission from reloading the page
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");

    if (!formData.name.trim()) {
      toast.error("Name cannot be empty.");
      return;
    }
    if (!formData.username.trim()) {
      toast.error("Username cannot be empty.");
      return;
    }
    if (formData.username.length < 6) {
      toast.error("Username must be at least 6 characters long.");
      return;
    }

    try {
      const response = await fetch(
        "https://zola.technology/api/company/employees/add/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.username[0] || "Error adding employee");
        throw new Error("Network response was not ok");
      }
      // Success notification
      toast.success("Employee added successfully");
      setIsModalOpen(false);
      // Refetch data to update the UI
      fetchData();
      handleTableRefresh();
      // Redirect to team management page
    } catch (error) {
      toast.error("Error adding employee");
    }
    handleModalToggle();
  };

  // Update form input state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle sorting based on selected option
  const handleSelect = (option) => {
    setSortOption(option);
    setIsOpen(false);
    const sortedData = [...tableData];
    switch (option) {
      case "Rating":
        sortedData.sort((a, b) => b.average_rating - a.average_rating);
        break;
      case "Name":
        sortedData.sort((a, b) =>
          a.employee.name.localeCompare(b.employee.name)
        );
        break;
      case "Feedbacks":
        sortedData.sort((a, b) => b.total_feedback - a.total_feedback);
        break;

      case "Rewards":
        sortedData.sort((a, b) => b.reward_total - a.reward_total);
        break;
      default:
        break;
    }
    setTableData(sortedData);
  };

  // Handle updating employee (dummy function)
  const handleUpdate = (index) => {
    setShowPopup(true);
    setSelectedItemIndex(index);
    console.log(index.employee.id);
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }
    // console.log(data[selectedItemIndex].employee.id, "selected employee id");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${selectedItemIndex.employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const updatedData = [...data];
      updatedData[selectedItemIndex] = {
        ...updatedData[selectedItemIndex],
        name: updatedValues.name,
        username: updatedValues.username,
      };
      setData(updatedData);
      toast.success("Employee data updated successfully!");
      // Refetch data to update the UI
      fetchData();
      handleTableRefresh();
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };

  // Handle deleting employee (dummy function)
  const handleDeleteClick = (item) => {
    setSelectedEmployee(item.employee);
    setIsDeleteModalOpen(true); // Open the delete modal
  };

  const filteredTableData =
    tableData?.filter((item) =>
      item?.employee?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  const cardData = data?.filter((item) => item?.employee) || [];

  const handleDeleteConfirm = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }

    setLoading(true);
    setDeletingIndex(selectedEmployee.id);

    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/delete/${selectedEmployee?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Filter out the deleted employee from data and tableData
        const updatedData = data.filter(
          (item) => item.employee.id !== selectedEmployee.id
        );
        setData(updatedData);
        setTableData(updatedData);

        toast.success(`${selectedEmployee?.name} deleted successfully!`);
      } else {
        throw new Error("Failed to delete employee");
      }
    } catch (error) {
      setError(error.message);
      toast.error(`Delete Error: ${error.message}`);
    } finally {
      setLoading(false);
      setDeletingIndex(null);
      setIsDeleteModalOpen(false); // Close the modal after deletion
      setSelectedEmployee(null); // Reset the selected employee
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false); // Close the delete modal
    setSelectedEmployee(null); // Reset the selected employee
  };

  const chartData = useMemo(() => {
    return {
      series: [
        {
          name: "Performance",
          data: chartdata,
        },
      ],
      options: {
        chart: {
          type: "area",
          height: 300,
          toolbar: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
        },
        grid: {
          show: true,
          borderColor: "#EAEAEC",
          strokeDashArray: 2,
          position: "back",
          dataLabels: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
          hover: { size: 0 },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: labels,
          labels: {
            style: {
              colors: "#6F707E",
              fontSize: "14px",
              fontWeight: 500,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#969696",
              fontSize: "12px",
            },
            formatter: (value) => `${value}`,
          },
          beginAtZero: true,
        },
        stroke: {
          curve: "smooth",
          width: 2,
          colors: ["#007BFF"], // Explicitly setting line color to blue
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: "#007BFF", // Blue gradient start
                opacity: 0.4,
              },
              {
                offset: 100,
                color: "#007BFF", // Blue gradient end
                opacity: 0,
              },
            ],
          },
        },
        colors: ["#007BFF"], // Explicitly setting chart color to blue
        tooltip: {
          y: {
            formatter: (val) => `${val}`,
          },
        },
      },
    };
  }, [labels, chartdata]);

  const handleViewAllToggle = () => setShowAll(!showAll);
  // Determine the number of cards to display based on screen size
  const numCardsToShow = showAll
    ? cardData.length
    : isSmallScreen
    ? 1
    : isMediumScreen
    ? 2
    : 4;

  const displayedCardData = showAll
    ? cardData
    : cardData.slice(0, numCardsToShow); // Adjust the number based on screen size if needed

  const [qrCodeData, setQrCodeData] = useState("");
  const [showQrCodeIndex, setShowQrCodeIndex] = useState(null);

  const handleShowQrCode = async (index) => {
    if (showQrCodeIndex === index) {
      setShowQrCodeIndex(null);
      setQrCodeData("");
      return;
    }

    const employeeUrl = tableData[index]?.employee?.employee_url;
    if (employeeUrl) {
      try {
        const qrCode = await QRCode.toDataURL(employeeUrl); // Generate QR code as a data URI
        setQrCodeData(qrCode);
        setShowQrCodeIndex(index);
      } catch (err) {
        console.error("Error generating QR code:", err);
      }
    }
  };

  // Pagination Handlers
  const handleCardPageChange = (direction) => {
    setCurrentCardPage((prevPage) =>
      direction === "next" ? prevPage + 1 : prevPage - 1
    );
  };

  const handleTablePageChange = (direction) => {
    setCurrentTablePage((prevPage) =>
      direction === "next" ? prevPage + 1 : prevPage - 1
    );
  };
  return (
    <div className="px-2 min-h-screen w-full bg-gray-50 dark:bg-gray-900">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        pauseOnHover
      />
      {isFetching ? (
        // <div className="flex justify-center items-center min-h-screen mt-[-150px] ">
        //   <div className="loader "></div>
        // </div>
        <></>
      ) : (
        <>
          {/* Header Section */}

          {/* Header Section */}
          <div className="flex justify-between mb-3">
            <div>
              <h2 className="text-[20px] font-medium text-gray-900 dark:text-gray-100">
                Employees
              </h2>
              <button
                aria-label="Add Employee"
                onClick={handleModalToggle}
                className="bg-blue-600 dark:bg-blue-700 font-bold text-white px-4 py-2 mt-4 rounded-lg shadow-sm hover:bg-blue-700 dark:hover:bg-blue-400 transition"
              >
                Add Employee
              </button>
            </div>
            <button
              aria-expanded={showAll}
              aria-label={showAll ? "Show less content" : "View all content"}
              className="text-blue-600 dark:text-blue-400 font-semibold text-sm mt-8 mr-2"
              onClick={handleViewAllToggle}
            >
              {showAll ? "Show Less" : "View All"}
            </button>
          </div>
          {/* Add Employee Modal */}

          {isModalOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50"
              aria-modal="true"
              role="dialog"
              onClick={handleModalToggle} // Close modal when overlay is clicked
            >
              <div
                className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden w-[90%] max-w-sm relative"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
              >
                <div className="bg-[#0C4EF8] p-4">
                  <h2 className="text-white font-semibold text-[20px">
                    Add Employee
                  </h2>
                </div>

                {/* Employee Form */}
                <form onSubmit={handleSubmit} className="space-y-4 p-4 mt-4">
                  {/* Full Name Field */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                      required
                    />
                  </div>

                  {/* Username Field */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                      Username
                    </label>
                    <input
                      type="text"
                      name="username"
                      value={formData.username}
                      onChange={handleInputChange}
                      className="w-full px-4 py-3 border border-gray-300 dark:border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-white"
                      required
                    />
                  </div>

                  {/* Save Button */}
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className="w-full bg-blue-600 dark:bg-blue-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-blue-700 dark:hover:bg-blue-400 transition"
                    >
                      Save Employee Details
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Employee Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-6 w-full">
            {displayedCardData.length === 0 ? (
              <p className="text-gray-500 dark:text-gray-400 text-center col-span-full">
                Employees details will appear here
              </p>
            ) : (
              displayedCardData.map((item, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-gray-800 p-4 rounded-xl flex flex-col items-start space-y-3 cursor-pointer hover transition"
                  onClick={() =>
                    navigate(`/dashboard/employee-profile/${item.employee.id}`)
                  }
                >
                  <div className="flex items-center gap-2">
                    <p className="w-12 h-12 rounded-full flex items-center justify-center text-white font-bold text-xl bg-blue-600">
                      {`${item.employee.name.split(" ")[0][0].toUpperCase()}${
                        item.employee.name.split(" ")[1]
                          ? item.employee.name.split(" ")[1][0].toUpperCase()
                          : ""
                      }`}
                    </p>

                    <h3 className="text-[16px] font-medium text-gray-900 dark:text-gray-100">
                      {item.employee.name.split(" ")[0]}{" "}
                      {item.employee.name.split(" ")[1]}
                    </h3>
                  </div>
                  <div
                    className={`text-lg flex items-center space-x-2 ${
                      item.performance_label === "Poor Employee"
                        ? "bg-red-100 text-red-600"
                        : "bg-blue-100 text-blue-600"
                    } px-3 py-0 rounded-full`}
                  >
                    <div className="flex items-center space-x-1 rounded-full">
                      {item.performance_label === "Poor Employee" ? (
                        <MdError className="text-red-600 " />
                      ) : (
                        <MdVerified className="text-blue-600 w-[24px]" />
                      )}
                      <span className="text-[14px] font-medium">
                        {item.performance_label}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 mt-1">
                    <span className=" dark:text-gray-100 text-gray-700 font-medium text-xl">
                      {item.employee.number_of_taps}
                      <span className="text-sm ml-2">taps</span>
                    </span>
                    <div className="flex items-center space-x-1">
                      <span className="text-gray-700 dark:text-gray-100 font-medium text-xl">
                        {item.average_rating}
                      </span>
                      <FaStar className="text-yellow-500" />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {/* Pagination for Cards */}
          <div className="flex justify-between items-center mt-4 mb-4">
            <button
              disabled={currentCardPage === 1}
              onClick={() => handleCardPageChange("prev")}
              className="px-4 py-2 rounded transition-all duration-200
      bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300
      hover:bg-gray-300 dark:hover:bg-gray-600 
      disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Previous
            </button>

            <span className="text-gray-800 dark:text-gray-200 font-medium">
              Page {currentCardPage} of {totalCardPages}
            </span>

            <button
              disabled={currentCardPage === totalCardPages}
              onClick={() => handleCardPageChange("next")}
              className="px-4 py-2 rounded transition-all duration-200
      bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300
      hover:bg-gray-300 dark:hover:bg-gray-600 
      disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next
            </button>
          </div>

          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg mb-6 transition-colors duration-300">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-[20px] text-[#292D32] dark:text-gray-100 font-medium">
                Employee <br /> Overview
              </h3>
              <div className="relative">
                <div className="border border-[#EAEAEC] dark:border-gray-600 rounded-md p-2">
                  <select
                    className="bg-white dark:bg-gray-700 dark:text-gray-200 text-[16px] focus:outline-none font-semibold cursor-pointer text-[#282A3E] dark:border-gray-500 rounded-md"
                    defaultValue="Monthly"
                  >
                    <option value="Monthly">Monthly</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="w-full h-66">
              <ReactApexChart
                options={{
                  ...chartData.options,
                  chart: { background: "transparent" },
                  xaxis: {
                    ...chartData.options.xaxis,
                    labels: { style: { colors: "gray" } },
                  },
                  yaxis: {
                    ...chartData.options.yaxis,
                    labels: { style: { colors: "gray" } },
                  },
                  grid: {
                    ...chartData.options.grid,
                    borderColor: "#EAEAEC",
                  },
                }}
                series={chartData.series}
                type="area"
                height={300}
              />
            </div>
          </div>

          {/* Employee Table */}

          <CompanyEmployeeTable
            refreshTable={refreshTable}
            onDeleteSuccess={handleTableRefresh}
          />

          {showQrCodeIndex !== null && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              aria-modal="true"
              role="dialog"
              onClick={() => setShowQrCodeIndex(null)} // Close modal on clicking the overlay
            >
              <div
                className="bg-white rounded-xl p-6 max-w-[500px] w-full text-center relative"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
              >
                <button
                  onClick={() => setShowQrCodeIndex(null)} // Close modal
                  className="float-right bg-gray-200 text-gray-700 hover:bg-gray-300 rounded-full p-2"
                  aria-label="Close QR Code Modal"
                >
                  <FaTimes className="h-5 w-5" />
                </button>

                <h3 className="text-xl font-semibold text-[#454861] mb-4">
                  QR Code for {tableData[showQrCodeIndex]?.employee.name}
                </h3>
                <div className="flex justify-center">
                  <img
                    src={qrCodeData}
                    alt="Employee QR Code"
                    className="w-[250px] h-[250px] object-contain border border-gray-300 rounded-md shadow-lg"
                  />
                </div>
              </div>
            </div>
          )}

          {showPopup && selectedItemIndex !== null && selectedItemIndex ? (
            <EditForm
              selectedItem={selectedItemIndex?.employee} // Use optional chaining
              onUpdate={handleUpdateData}
              onClose={handleEditFormClose}
            />
          ) : null}

          {isDeleteModalOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity"
              onClick={handleDeleteCancel}
            >
              <div
                className="bg-white dark:bg-gray-800 rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4 transition-colors duration-300"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
              >
                {/* Warning Icon */}
                <FaExclamationTriangle className="text-[#EE6F7C] dark:text-[#F87171] text-5xl mx-auto" />

                {/* Modal Title */}
                <h2 className="text-2xl text-[#454861] dark:text-gray-100 font-semibold">
                  Delete Employee?
                </h2>

                {/* Warning Message */}
                <p className="text-[#6F707E] dark:text-gray-300 font-normal text-[18px]">
                  This action cannot be undone.
                </p>

                {/* Buttons */}
                <div className="flex justify-center space-x-4 mt-4">
                  <button
                    aria-label="cancel"
                    className="bg-[#0C4EF8] dark:bg-blue-600 text-[#FEFEFF] font-semibold px-8 py-2 rounded-lg hover:bg-blue-700 dark:hover:bg-blue-500 transition"
                    onClick={handleDeleteCancel} // Closes the delete confirmation modal
                  >
                    Cancel
                  </button>
                  <button
                    aria-label="delete"
                    className="border border-[#F7BCC2] dark:border-[#F87171] text-[#E6283B] dark:text-[#F87171] font-semibold px-8 py-2 rounded-lg hover:bg-red-600 hover:text-white dark:hover:bg-red-500 transition"
                    onClick={handleDeleteConfirm} // Confirms deletion of the selected employee
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {isExpired && <Subscription />}
    </div>
  );
}
export default CompanyEmployees;
