import React from "react";
import GaugeChart from "react-gauge-chart";

const SemiCircleChart = ({ value = 0 }) => {
  const sectionLabels = [
    "-100",
    "-80",
    "-60",
    "-40",
    "-20",
    "-0",
    "20",
    "40",
    "60",
    "80",
    "100",
  ];
  const sectionColors = [
    "#FF0000",
    "#FF3300",
    "#FF6600",
    "#FF9900",
    "#FFCC00",
    "#FFFF00",
    "#CCFF00",
    "#99FF00",
    "#66FF00",
    "#00FF00",
    "#008000",
  ];

  // Normalize value from -100 to 100 → to range 0-1 for GaugeChart
  const normalizedValue = (value + 100) / 200;

  return (
    <div className="w-full flex flex-col items-center relative">
      {/* Gauge Chart */}
      <GaugeChart
        id="semi-circle-gauge"
        nrOfLevels={sectionColors.length} // Match number of segments to labels
        colors={sectionColors}
        arcWidth={0.3}
        percent={normalizedValue}
        textColor="transparent"
        needleColor="#333"
        needleBaseColor="#333"
        formatTextValue={() => ""}
      />
      {/* Value Display */}
      <div className="text-center font-bold text-lg mt-4">
        {value.toFixed(2)}
      </div>
      <div className="text-center text-gray-500">Overall NPS</div>
      {/* Legend */}
      <div className="flex justify-between w-full max-w-[300px] mt-2">
        {sectionLabels.map((label, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className="w-4 h-4 rounded-full"
              style={{ backgroundColor: sectionColors[index] }}
            ></div>
            <span className="text-xs font-bold text-gray-800 mt-1">
              {label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SemiCircleChart;
