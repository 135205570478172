import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaStar } from "react-icons/fa";
import { useTimeRange } from "../../../context/TimeRangeContext";

const EmployeeRewardsTable = () => {
  const [rewardData, setRewardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { timeRange } = useTimeRange();


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/employees/performance/rewards/?filter=${timeRange}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRewardData(response.data);
      } catch (error) {
        console.error("Error fetching rewards data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  return (
    <div>
      {/* Header Section */}
      <div className="bg-white dark:bg-gray-800 p-4 flex md:space-x-2 sm:space-x-4 xs:space-x-12 items-center w-full rounded-t-[8px] justify-between mt-5 shadow-md">
  <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
    Employee Rewards
  </h2>
</div>

{/* Rewards Table */}
<div className="overflow-x-auto">
  <table className="min-w-full table-auto border-separate border-spacing-y-3">
    <thead className="text-gray-700 dark:text-gray-300 text-[16px] bg-gray-100 dark:bg-gray-900">
      <tr>
        <th className="px-4 py-2 text-left font-medium">S/N</th>
        <th className="px-4 py-2 text-left font-medium">Name</th>
        <th className="px-4 py-2 text-left font-medium">Performance</th>
        <th className="px-4 py-2 text-left font-medium">Feedbacks</th>
        <th className="px-4 py-2 text-left font-medium">Rewards</th>
        <th className="px-4 py-2 text-left font-medium">Rating</th>
      </tr>
    </thead>
    <tbody>
      {rewardData?.data?.length > 0 ? (
        rewardData.data.map((employeeData, index) => (
          <tr
            key={employeeData.employee.id}
            className="bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 text-[16px] hover:shadow-md dark:hover:bg-gray-600 transition"
          >
            <td data-cell="S/N">{index + 1}</td>
            <td data-cell="Name" className="flex items-center space-x-2">
              <img
                src={employeeData.employee.company.profile_image}
                alt={employeeData.employee.name}
                className="w-10 h-10 rounded-full object-contain border border-gray-300 dark:border-gray-600"
              />
              <span className="font-semibold">{employeeData.employee.name}</span>
            </td>
            <td data-cell="Performance" className="font-semibold">
              {employeeData.performance_score}%
            </td>
            <td data-cell="Feedbacks" className="font-semibold">
              {employeeData.total_feedback}
            </td>
            <td data-cell="Rewards" className="font-semibold">
              {employeeData.reward_currency} {employeeData.reward_total}
            </td>
            <td data-cell="Ratings" className="font-semibold">
              <div className="flex items-center">
                {employeeData.average_rating}{" "}
                <FaStar className="text-yellow-400 ml-1" />
              </div>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="6" className="text-center py-4 text-gray-700 dark:text-gray-300">
            {loading ? "Loading..." : "No data available."}
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>

    </div>
  );
};

export default EmployeeRewardsTable;
