import React from "react";
import { motion } from "framer-motion";

const PrivacyPolicy = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="p-8 md:p-24"
    >
      <motion.h1
        className="text-4xl font-bold mb-8 text-center"
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 50 }}
      >
        Privacy Policy
      </motion.h1>

      <motion.p
        className="text-lg text-center mb-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
      >
        Last Updated: {new Date().toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric" })}
      </motion.p>

      {/* Sections */}
      {[
        {
          title: "1. Introduction",
          content:
            "Welcome to Zola! This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our services. By accessing or using our services, you consent to the practices described in this Privacy Policy.",
        },
        {
          title: "2. Information We Collect",
          content: (
            <>
              <p>We collect the following types of information:</p>
              <ul className="list-disc ml-6">
                <li><strong>Personal Information:</strong> Name, email, phone number, account credentials, and payment details.</li>
                <li><strong>Usage Data:</strong> IP address, browser type, operating system, referring URLs, pages visited, and other analytical data.</li>
                <li><strong>Content Data:</strong> Any content you submit, post, or display through our services.</li>
              </ul>
            </>
          ),
        },
        {
          title: "3. How We Use Your Information",
          content: (
            <>
              <p>Your information is used to:</p>
              <ul className="list-disc ml-6">
                <li>Provide, maintain, and improve our services.</li>
                <li>Process payments and manage subscriptions.</li>
                <li>Respond to customer inquiries and support requests.</li>
                <li>Monitor and analyze service usage for security and improvements.</li>
                <li>Prevent fraud, unauthorized access, and other violations.</li>
              </ul>
            </>
          ),
        },
        {
          title: "4. Data Sharing and Disclosure",
          content: (
            <>
              <p>We do not sell or rent your personal data. However, we may share it with:</p>
              <ul className="list-disc ml-6">
                <li>Service providers assisting with payment processing, hosting, and analytics.</li>
                <li>Law enforcement or regulatory authorities when required by law.</li>
                <li>Third parties in connection with a business transfer (e.g., merger, acquisition).</li>
              </ul>
            </>
          ),
        },
        {
          title: "5. Data Security",
          content: "We implement industry-standard security measures to protect your data but cannot guarantee absolute security. You are responsible for safeguarding your account credentials.",
        },
        {
          title: "6. Cookies and Tracking Technologies",
          content: "We use cookies and similar technologies to enhance your experience, analyze trends, and customize content. You can manage cookie settings in your browser.",
        },
        {
          title: "7. Your Rights and Choices",
          content: (
            <>
              <p>You have the right to:</p>
              <ul className="list-disc ml-6">
                <li>Access, update, or delete your personal data.</li>
                <li>Opt out of marketing communications.</li>
                <li>Request a copy of your personal information.</li>
              </ul>
            </>
          ),
        },
        {
          title: "8. Retention of Data",
          content: "We retain your data as long as necessary to fulfill our services and legal obligations. You can request deletion at any time.",
        },
        {
          title: "9. Third-Party Links",
          content: "Our services may contain links to third-party websites. We are not responsible for their privacy practices and encourage you to review their policies.",
        },
        {
          title: "10. Children's Privacy",
          content: "Our services are not intended for children under 18. We do not knowingly collect data from children.",
        },
        {
          title: "11. Changes to this Policy",
          content: "We may update this Privacy Policy periodically. If significant changes occur, we will notify you via email or through our website.",
        },
        {
          title: "12. Contact Us",
          content: (
            <>
              <p>
                If you have any questions about this Privacy Policy, please contact us at{" "}
                <a href="mailto:support@zolatap.com" className="text-blue-500 underline">
                  support@zolatap.com
                </a>.
              </p>
            </>
          ),
        },
      ].map((section, index) => (
        <motion.section
          key={index}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 * (index + 1) }}
          className="mb-10"
        >
          <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
          <div className="text-lg leading-relaxed">{section.content}</div>
        </motion.section>
      ))}
    </motion.div>
  );
};

export default PrivacyPolicy;
