import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const EditForm = ({
  selectedItem = { name: "", username: "", reward: "" },
  onClose,
  onUpdate,
  showRewardField = false, // Controls reward field visibility
}) => {
  const [formValues, setFormValues] = useState({
    name: selectedItem.name,
    username: selectedItem.username,
    reward: selectedItem.reward || "not_rewarded", // Default to "not_rewarded"
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (
      selectedItem &&
      (selectedItem.name || selectedItem.username || selectedItem.reward)
    ) {
      setFormValues({
        name: selectedItem.name || "",
        username: selectedItem.username || "",
        reward: selectedItem.reward || "not_rewarded",
      });
    }
  }, [selectedItem]);

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name.trim()) {
      newErrors.name = "Full name is required.";
    }
    if (formValues.username.length < 6) {
      newErrors.username = "Username must be at least 6 characters long.";
    }
    return newErrors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await onUpdate(formValues);
      onClose();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity"
      onClick={onClose}
    >
      <div
        className="bg-white dark:bg-gray-800 rounded-xl w-[90%] max-w-sm space-y-4 transition-colors duration-300"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <h2 className="text-lg rounded-t-xl p-4 font-semibold text-white bg-[#0C4EF8] dark:bg-blue-600">
          Edit Item
        </h2>

        <form onSubmit={handleFormSubmit}>
          {/* Full Name */}
          <div className="mb-4 px-6">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-[#282A3E] dark:text-gray-300"
            >
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formValues.name}
              onChange={handleFormChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-black dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              required
            />
            {errors.name && (
              <p className="text-sm text-red-600 mt-1">{errors.name}</p>
            )}
          </div>

          {/* Username */}
          <div className="mb-4 px-6">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-[#282A3E] dark:text-gray-300"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={formValues.username}
              onChange={handleFormChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-black dark:text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400"
              required
            />
            {errors.username && (
              <p className="text-sm text-red-600 mt-1">{errors.username}</p>
            )}
          </div>

          {/* Reward Status */}
          {showRewardField && (
            <div className="mb-4 px-6">
              <label className="block text-sm font-medium text-[#282A3E] dark:text-gray-300">
                Reward Status
              </label>
              <div className="mt-2 space-y-2">
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="reward"
                    value="rewarded"
                    checked={formValues.reward === "rewarded"}
                    onChange={handleFormChange}
                    className="focus:ring-blue-500 dark:focus:ring-blue-400 text-blue-600 dark:text-blue-400"
                  />
                  <span className="text-sm text-[#282A3E] dark:text-gray-300">
                    Rewarded
                  </span>
                </label>
                <label className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="reward"
                    value="not_rewarded"
                    checked={formValues.reward === "not_rewarded"}
                    onChange={handleFormChange}
                    className="focus:ring-blue-500 dark:focus:ring-blue-400 text-blue-600 dark:text-blue-400"
                  />
                  <span className="text-sm text-[#282A3E] dark:text-gray-300">
                    Not Rewarded
                  </span>
                </label>
              </div>
            </div>
          )}

          {/* Submit Button */}
          <div className="flex p-6">
            <button
              aria-label="Update"
              type="submit"
              className="px-4 py-2 w-full bg-[#0C4EF8] dark:bg-blue-500 text-white text-[16px] font-bold rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 transition-all"
              style={{
                boxShadow:
                  "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
              }}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

EditForm.propTypes = {
  selectedItem: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    reward: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showRewardField: PropTypes.bool, // Controls reward field visibility
};

export default EditForm;
