import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function MobileSurvey() {
  const [survey, setSurvey] = useState(null);
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState({});
  const { company_username } = useParams();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null); // State to store company data
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(
          `https://zola.technology/api/company/get/${company_username}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch company data");
        }
        const data = await response.json();
        console.log(data.profile_image);
        setCompanyData(data);
      } catch (error) {
        setError(error.message);
        console.error("Error fetching company data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [company_username]);

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        // const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://zola.technology/api/company/${company_username}/surveys/survey/get-active/`,
          {
            headers: {
              // Authorization: `Bearer ${token}`,
            },
          }
        );
        setSurvey(response.data);
      } catch (error) {
        console.error("Error fetching survey:", error);
        setError("Failed to load survey. Please try again.");
      }
    };

    fetchSurvey();
  }, [company_username]);

  const handleResponseChange = (questionId, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
  };

  const handleCheckboxChange = (questionId, choice) => {
    setResponses((prevResponses) => {
      const currentSelections = prevResponses[questionId] || [];
      const updatedSelections = currentSelections.includes(choice)
        ? currentSelections.filter((item) => item !== choice)
        : [...currentSelections, choice];
      return {
        ...prevResponses,
        [questionId]: updatedSelections,
      };
    });
  };

  const handleSubmit = async () => {
    const formattedAnswers = survey.questions.map((question) => {
      const response = responses[question.id];
      let formattedResponse;

      if (question.question_type === "multi_choice") {
        formattedResponse = response || [];
      } else if (question.question_type === "single_choice") {
        formattedResponse = response || "";
      } else if (question.question_type === "numeric_choice") {
        formattedResponse = response || 0;
      } else {
        formattedResponse = response || "";
      }

      return {
        question_id: question.id,
        response: formattedResponse,
      };
    });

    const payload = {
      survey_id: survey.id,
      answers: formattedAnswers,
    };

    console.log("Survey submission data:", JSON.stringify(payload, null, 2));

    // const confirmSubmission = window.confirm(
    //   "Are you sure you want to submit your survey responses?"
    // );

    // if (!confirmSubmission) {
    //   console.log("Survey submission canceled by the user.");
    //   return;
    // }

    try {
      await axios.post(
        `https://zola.technology/api/company/${company_username}/surveys/survey/get-responses/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/survey/success");
    } catch (error) {
      console.error("Error submitting survey:", error);
      toast.error("Failed to submit the survey. Please try again.");
    }
  };

  if (error)
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 space-y-6">
        <p className="text-xl font-semibold text-gray-700 dark:text-gray-200">
          {error === "Failed to load survey. Please try again."
            ? "Oops! There are no active surveys available at the moment."
            : error}
        </p>
        <button
          onClick={() => window.history.back()} // Go back to the previous page
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
        >
          Go Back
        </button>
      </div>
    );

  if (!survey)
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
        <div className="animate-pulse text-gray-500 dark:text-gray-400">
          Loading survey...
        </div>
      </div>
    );

  return (
    <div className="py-6 bg-white dark:bg-gray-900 min-h-screen font-outfit">
      <ToastContainer />
      {/* Survey Header */}
      <div className="mx-auto flex items-center gap-4 dark:bg-gray-800 justify-center">
        <img
          src={companyData?.profile_image}
          className="w-20 object-contain bg-gray-50 h-20 rounded-full"
          alt=""
        />
        <h1 className="text-xl md:text-4xl font-semibold text-gray-900 dark:text-gray-100">
          {survey.title}
        </h1>
      </div>

      {/* Survey Questions */}
      <div className="space-y-6 mt-4">
        {survey.questions.map((question, index) => (
          <div key={question.id} className="w-full transition">
            <h2 className="font-light text-gray-900 dark:text-gray-100 text-center">
              {question.question_text}
            </h2>
            <div className="mt-4 space-y-3">
              {question.question_type === "paragraph" && (
                <textarea
                  className="w-full py-3 border-2 border-transparent rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white outline-none transition-all duration-300 focus:border-blue-600 focus:bg-white dark:focus:bg-gray-700"
                  rows="4"
                  placeholder="Type your answer..."
                  value={responses[question.id] || ""}
                  onChange={(e) =>
                    handleResponseChange(question.id, e.target.value)
                  }
                />
              )}

              {question.question_type === "single_choice" && (
                <div className="flex flex-col gap-3">
                  {Object.entries(question.choices).map(([key, value]) => (
                    <label
                      key={key}
                      className={`flex items-center bg-gray-100 dark:bg-gray-700 rounded-lg p-3 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 
        ${
          responses[question.id] === value
            ? "ring-2 ring-blue-500 dark:ring-blue-400" // Highlight selected choice
            : ""
        }`}
                    >
                      <input
                        type="radio"
                        name={`question-${question.id}`}
                        value={value}
                        checked={responses[question.id] === value}
                        onChange={() =>
                          handleResponseChange(question.id, value)
                        }
                        className="form-radio text-blue-600 dark:text-blue-400 mr-3 hidden"
                      />
                      <span className="text-gray-900 dark:text-white">
                        {value}
                      </span>
                    </label>
                  ))}
                </div>
              )}

              {question.question_type === "numeric_choice" && (
                <div className="flex flex-col gap-3">
                  <div className="flex justify-center gap-4">
                    {Object.entries(question.choices)
                      .slice(0, 6) // First 6 choices (0-5)
                      .map(([key, value]) => (
                        <label
                          key={key}
                          className={`w-10 h-10 flex items-center justify-center rounded-full text-white cursor-pointer 
            ${
              responses[question.id] === value ? "bg-green-500" : "bg-blue-600"
            } hover:bg-blue-200`}
                        >
                          <input
                            type="radio"
                            name={`question-${question.id}`}
                            value={value}
                            checked={responses[question.id] === value}
                            onChange={() =>
                              handleResponseChange(question.id, value)
                            }
                            className="hidden"
                          />
                          <span className="text-sm font-semibold">
                            {responses[question.id] === value ? "✔" : value}
                          </span>
                        </label>
                      ))}
                  </div>

                  <div className="flex justify-center gap-4">
                    {Object.entries(question.choices)
                      .slice(6, 11) // Last 5 choices (6-10)
                      .map(([key, value]) => (
                        <label
                          key={key}
                          className={`w-10 h-10 flex items-center justify-center rounded-full text-white cursor-pointer 
            ${
              responses[question.id] === value ? "bg-green-500" : "bg-blue-600"
            } hover:bg-blue-200`}
                        >
                          <input
                            type="radio"
                            name={`question-${question.id}`}
                            value={value}
                            checked={responses[question.id] === value}
                            onChange={() =>
                              handleResponseChange(question.id, value)
                            }
                            className="hidden"
                          />
                          <span className="text-sm font-semibold">
                            {responses[question.id] === value ? "✔" : value}
                          </span>
                        </label>
                      ))}
                  </div>
                </div>
              )}

              {question.question_type === "multi_choice" && (
                <div className="flex flex-col gap-3">
                  {Object.entries(question.choices).map(([key, value]) => (
                    <label
                      key={key}
                      className={`flex items-center justify-between rounded-lg p-4 cursor-pointer transition duration-300
        ${
          responses[question.id]?.includes(value)
            ? "bg-green-600 text-white"
            : "bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
        }`}
                    >
                      <input
                        type="checkbox"
                        value={value}
                        checked={
                          responses[question.id]?.includes(value) || false
                        }
                        onChange={() =>
                          handleCheckboxChange(question.id, value)
                        }
                        className="hidden"
                      />
                      <span className="text-lg font-medium">{value}</span>

                      <div
                        className={`w-6 h-6 flex items-center justify-center rounded-full border-2 transition-all duration-300
          ${
            responses[question.id]?.includes(value)
              ? "bg-white text-blue-600 border-white"
              : "border-gray-500 dark:border-gray-400"
          }`}
                      >
                        {responses[question.id]?.includes(value) && "✔"}
                      </div>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Submit Button */}
      <div className="mt-10 flex justify-center">
        <button
          onClick={handleSubmit}
          className="px-6 py-3 w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white text-lg font-semibold rounded-lg shadow-lg transform transition-transform"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default MobileSurvey;
