import { useState, useEffect } from "react";
import { useTimeRange } from "../../../context/TimeRangeContext";

const Leaders = () => {
    const [employees, setEmployees] = useState([]);
    const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchTopEmployees = async () => {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage
      if (!token) return;

      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/rewards/?filter=${timeRange}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        // Extract top 3 employees
        const topEmployees = data.data.slice(0, 3).map((item) => ({
          name: item.employee.name,
          reviews: item.total_feedback,
          rank: item.rank,
          image:
            item.employee.company.profile_image ||
            "https://randomuser.me/api/portraits/men/10.jpg",
        }));

        setEmployees(topEmployees);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchTopEmployees();
  }, [timeRange]);

  if (employees.length < 3) {
    return <p>Loading...</p>;
  }
  const period1 = timeRange;
  const period = period1.charAt(0).toUpperCase() + period1.slice(1);
    return (
      <div className="bg-black text-white md:h-[70vh] rounded-lg">
        <h2 className="text-4xl text-center font-semibold mb-6 pt-3">
          Top 3 Employees of the {period}
        </h2>
  
        <div className="blocks">
          <div className="block-podium">
            <div className="absolute -top-40 md:-top-36 left-4 flex flex-col items-center space-y-1">
              <img
                src={employees[1].image}
                alt={employees[1].name}
                className="w-10 md:w-14 h-10 md:h-14 rounded-full border-2 object-fit border-white"
              />
              <p className="text-sm font-bold">{employees[1].name}</p>
              <span className="text-xs text-center bg-blue-600 px-2 py-1 rounded-lg">
                {employees[1].reviews.toLocaleString()} <br />
                Reviews Generated
              </span>
            </div>
  
            <span>2</span>
          </div>
  
          <div className="block-podium">            <div className="absolute -top-40 md:-top-36 left-4 flex flex-col items-center space-y-1">

              <img
                src={employees[0].image}
                alt={employees[0].name}
                className="w-10 md:w-14 h-10 md:h-14 rounded-full border-2 object-fit border-white"
                
              />
              <p className="text-sm font-bold">{employees[0].name}</p>
              <span className="text-xs text-center bg-yellow-400 text-black px-2 py-1 rounded-lg">
                {employees[0].reviews.toLocaleString()} <br />
                Reviews Generated
              </span>
            </div>
            <span>1</span>
          </div>
          <div className="block-podium">
            <div className="absolute -top-40 md:-top-36 left-4 flex flex-col items-center space-y-1">
              <img
                src={employees[2].image}
                alt={employees[2].name}
                className="w-10 md:w-14 h-10 md:h-14 rounded-full border-2 object-fit border-white"
                
              />
              <p className="text-sm font-bold">{employees[2].name}</p>
              <span className="text-xs text-center bg-blue-600 px-2 py-1 rounded-lg">
                {employees[2].reviews.toLocaleString()} <br /> Reviews Generated
              </span>
            </div>
  
            <span>3</span>
          </div>
        </div>
      </div>
    );
  };
  
  export default Leaders;
  