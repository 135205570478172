import { useState, useContext, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaBars, FaChevronDown, FaSearch } from "react-icons/fa";
import ZolaLogo from "../../HQDashboard/components/sidebar/Layer_1.png";
import { AuthContext } from "../../context/AuthContext";
import { IoIosNotificationsOutline } from "react-icons/io";

const Topbar = ({ toggleSidebar, theme, toggleTheme, logout }) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const dropdownRef = useRef(null); // Ref for dropdown
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const profileImage = userData?.profile_image;
  const isHq = userData?.is_hq_company;
  const navigate = useNavigate();
  const notificationRef = useRef();

  const handleNotificationToggle = () => {
    setIsNotificationOpen((prev) => !prev); // Toggle the notification dropdown
  };

  const handleClickOutside = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setIsNotificationOpen(false); // Close the dropdown only when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    logout();
  };

  const handleSwitchDashboard = () => {
    navigate("/hqdashboard", { replace: true });
  };

  if (!currentUser || !isAuthenticated) {
    return null;
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const notificationsData = [];

  return (
    <div className="flex items-center justify-between px-6 py-4 lg:bg-white border-b border-[#EAEAEC] shadow-md dark:shadow-lg dark:bg-gray-900 dark:border-gray-700 xs:bg-[#f5f8ff] md:bg-[#f5f8ff]">
      {/* <div
        className={`lg:flex items-center text-3xl ${
          searchVisible ? "flex" : "hidden"
        }`}
      >
        <FaSearch className="text-gray-400 text-xl mr-2 ml-8" />
        <input
          type="text"
          placeholder="Search anything here"
          className="w-full outline-none bg-transparent text-sm text-gray-500"
        />
      </div> */}

      <button
          aria-label="Toggle Sidebar"
          className="md:hidden p-2 text-gray-700 dark:text-gray-300 z-50"
          onClick={toggleSidebar}
        >
          <FaBars className="h-5 w-4" />
        </button>

      {/* {!searchVisible && (
        <button
          onClick={() => setSearchVisible(true)}
          className="lg:hidden ml-8 p-2 text-gray-500 focus:outline-none"
          aria-label="Open search"
        >
          <FaSearch className="text-xl" />
        </button>
      )} */}
      <div></div>

      <div className="flex items-center space-x-4">
        <button
          aria-label="Notifications"
          onClick={handleNotificationToggle}
          ref={notificationRef}
          className="p-2 text-gray-700 dark:text-gray-300"
        >
          <IoIosNotificationsOutline className="text-3xl" />
        </button>

        {/* Notification Dropdown */}
        {isNotificationOpen && (
          <div className="absolute top-12 right-1 w-80 bg-white dark:bg-gray-800 shadow-lg rounded-lg border border-gray-200 dark:border-gray-700 z-50">
            <div className="p-4 border-b border-gray-200 dark:border-gray-700">
              <h3 className="text-base font-semibold text-gray-700 dark:text-gray-300">
                Notifications
              </h3>
            </div>
            {notificationsData.length > 0 ? (
              <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                {notificationsData.map((notification) => (
                  <li
                    key={notification.id}
                    className="p-4 flex items-start space-x-3 hover:bg-gray-100 dark:hover:bg-gray-700 transition"
                  >
                    <div
                      className={`flex items-center justify-center w-8 h-8 ${notification.bgColor} ${notification.textColor} rounded-full`}
                    >
                      {notification.icon}
                    </div>
                    <div>
                      <p className="text-sm text-gray-700 dark:text-gray-300 font-medium">
                        {notification.message}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                        {notification.time}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="p-4 text-center text-gray-500 dark:text-gray-400">
                <p>No notifications at the moment! 🎉</p>
              </div>
            )}
          </div>
        )}

        <div className="h-8 w-[2px] bg-gray-400 hidden lg:block"></div>

        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="flex items-center space-x-2 focus:outline-none"
          >
            <img
              src={userData.profile_image}
              alt="User Avatar"
              className="w-8 h-8 rounded-full object-cover"
            />
            <h2 className="text-lg font-normal uppercase dark:text-white">
              {userData.username}
            </h2>
            <FaChevronDown className="text-gray-600 dark:text-gray-400" />
          </button>

          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg dark:bg-gray-800 dark:border-gray-700 z-50">
              <ul className="py-2">
                {isHq && (
                  <button
                    aria-label="Switch to HQ Dashboard"
                    className="block w-full px-4 py-2 text-center text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
                    onClick={handleSwitchDashboard}
                  >
                    Switch HQ Dashboard
                  </button>
                )}
                <li>
                  <button
                    className="block w-full text-center px-4 py-2 text-sm text-red-500 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => logout()}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
