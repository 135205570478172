import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { BranchContext } from "./../../context/BranchContext";

const EmployeePerformanceOverview = () => {
  const { branches, loading } = useContext(BranchContext);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Branch Taps",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: "#8c9098",
            fontSize: "12px",
          },
        },
        tickPlacement: "on",
      },
      yaxis: {
        min: 0,
        forceNiceScale: true,
        labels: {
          style: {
            colors: "#8c9098",
            fontSize: "12px",
          },
          formatter: (value) => value.toLocaleString(),
        },
        title: {
          text: "Performance (in taps)",
          style: {
            color: "#8c9098",
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        theme: "dark",
        marker: {
          show: true,
        },
        y: {
          formatter: (value) => value.toLocaleString(),
        },
      },
      markers: {
        size: 6,
        colors: ["#fff"],
        strokeColors: "#0055ff",
        strokeWidth: 2,
        hover: {
          size: 8,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#cce5ff"],
          shadeIntensity: 1,
          type: "vertical",
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0.1,
          stops: [0, 100],
        },
      },
      grid: {
        borderColor: "#e7e7e7",
      },
      colors: ["#0055ff"],
    },
  });

  useEffect(() => {
    if (!loading && branches.length > 0) {
      const branchNames = branches.map((branch) => branch.name);
      const branchTaps = branches.map((branch) => branch.taps);

      setChartData((prevData) => ({
        ...prevData,
        series: [
          {
            ...prevData.series[0],
            data: branchTaps,
          },
        ],
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: branchNames,
          },
        },
      }));
    }
  }, [branches, loading]);

  return (
    <div className="bg-white rounded-lg p-6 mb-4">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">
        Branch Taps Overview
      </h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="area"
          height={350}
        />
      )}
    </div>
  );
};

export default EmployeePerformanceOverview;
