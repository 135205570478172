import { useState, useEffect } from "react";
import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaStar,
  FaEye,
  FaEdit,
  FaTrash,
  FaFilter,
} from "react-icons/fa";
import { GoSearch } from "react-icons/go";
import axios from "axios";
import { useTimeRange } from "../../../context/TimeRangeContext";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EditForm from "../EditForm";

const EmployeesTable = () => {
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { timeRange } = useTimeRange();
  const navigate = useNavigate();
  const pageSize = 5; // Set page size

  const [data, setData] = useState([]);
  
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  useEffect(() => {
    fetchEmployees(currentPage);
  }, [currentPage, timeRange]);

  const fetchEmployees = async (page) => {
    try {
      const token = localStorage.getItem("token"); // Get token from localStorage

      const response = await axios.get(
        `https://zola.technology/api/company/employees/performance/rewards/?filter=${timeRange}&page=${page}&page_size=${pageSize}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setEmployees(response.data.data);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  // Sorting function
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  // Get sorting icon
  const getSortIcon = (column) => {
    if (sortColumn === column) {
      return sortOrder === "asc" ? (
        <FaSortUp className="inline ml-1" />
      ) : (
        <FaSortDown className="inline ml-1" />
      );
    }
    return <FaSort className="inline ml-1" />;
  };

  // Sorting logic
  const sortedData = [...employees].sort((a, b) => {
    if (!sortColumn) return 0;
    let valueA = a[sortColumn];
    let valueB = b[sortColumn];

    if (!isNaN(valueA) && !isNaN(valueB)) {
      valueA = Number(valueA);
      valueB = Number(valueB);
    }

    return sortOrder === "asc"
      ? valueA > valueB
        ? 1
        : -1
      : valueA < valueB
      ? 1
      : -1;
  });


 const handleUpdate = (index) => {
    setShowPopup(true);
    setSelectedItemIndex(index);
    console.log(index.employee.id);
  };

  const handleEditFormClose = () => setShowPopup(false);

  const handleUpdateData = async (updatedValues) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No token found");
      toast.error("No token found");
      return;
    }
    // console.log(data[selectedItemIndex].employee.id, "selected employee id");
    try {
      const response = await fetch(
        `https://zola.technology/api/company/employees/update/${selectedItemIndex.employee.id}/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updatedValues.name,
            username: updatedValues.username,
          }),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok");

      const updatedData = [...data];
      updatedData[selectedItemIndex] = {
        ...updatedData[selectedItemIndex],
        name: updatedValues.name,
        username: updatedValues.username,
      };
      setData(updatedData);
      toast.success("Employee data updated successfully!");
      // Refetch data to update the UI
      
    } catch (error) {
      setError(error.message);
      toast.error(`Update Error: ${error.message}`);
    }
  };

  
  return (
    <div className="bg-white shadow-md rounded-lg p-2 md:p-4 mt-12">
       <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={true}
              closeOnClick
              pauseOnHover
            />
      {/* Header */}
      <div className="flex gap-4 flex-col md:flex-row justify-between items-center pb-4">
        <h2 className="text-lg font-semibold">Employees</h2>
        <div className="flex space-x-2">
          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search anything here"
              className="border rounded-lg px-3 py-2 pl-10 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <GoSearch className="absolute left-3 top-3 text-gray-500" />
          </div>
          {/* Filter Button */}
          <button className="border px-4 py-2 rounded-lg flex items-center space-x-2">
            <FaFilter className="text-gray-700" />
            <span className="text-sm">Filter</span>
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          {/* Table Header */}
          <thead>
            <tr className="text-gray-400 text-left text-sm">
              <th className="p-3">
                <input type="checkbox" />
              </th>
              <th
                className="p-3 font-medium cursor-pointer"
                onClick={() => handleSort("id")}
              >
                S/N {getSortIcon("id")}
              </th>
              <th
                className="p-3 font-medium cursor-pointer"
                onClick={() => handleSort("employee.name")}
              >
                Name {getSortIcon("employee.name")}
              </th>
              <th
                className="p-3 font-medium cursor-pointer"
                onClick={() => handleSort("performance_score")}
              >
                Performance {getSortIcon("performance_score")}
              </th>
              <th className="p-3 font-medium">Reviews</th>
              <th
                className="p-3 font-medium cursor-pointer"
                onClick={() => handleSort("total_taps")}
              >
                Engagement {getSortIcon("total_taps")}
              </th>
              <th
                className="p-3 font-medium cursor-pointer"
                onClick={() => handleSort("reward_total")}
              >
                Rewards {getSortIcon("reward_total")}
              </th>
              <th className="p-3 font-medium">Action</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {sortedData
              .filter((emp) =>
                emp.employee.name.toLowerCase().includes(search.toLowerCase())
              )
              .map((emp, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="hidden md:block p-3">
                    <input type="checkbox" />
                  </td>
                  <td data-cell="S/N" className="p-3">{emp.employee.id}</td>
                  <td data-cell="Name" className="p-3 flex items-center space-x-2">
                    <img
                      src={emp.employee.company.profile_image}
                      alt={emp.employee.name}
                      className="w-8 h-8 rounded-full"
                    />
                    <span>{emp.employee.name}</span>
                  </td>
                  <td data-cell="Performance" className="p-3">{emp.performance_score}%</td>
                  <td data-cell="Reviews" className="p-3">
                    <span className="bg-black text-white px-2 py-1 rounded-lg flex items-center w-fit">
                      <FaStar className="text-yellow-400 mr-1" />{" "}
                      {emp.average_rating} ({emp.total_feedback})
                    </span>
                  </td>
                  <td data-cell="Engagements" className="p-3">{emp.total_taps}</td>
                  <td data-cell="Rewards" className="p-3">{emp.reward_total} {emp.reward_currency}</td>
                  <td data-cell="Action" className="p-3 flex space-x-2">
                  <button className="bg-blue-500 p-2 rounded-lg text-white" onClick={() => handleUpdate(emp)}>
                      <FaEdit />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`px-4 py-2 rounded-lg ${
            currentPage === 1 ? "bg-gray-300" : "bg-blue-500 text-white"
          }`}
        >
          Previous
        </button>
        <span className="text-sm">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages}
          className={`px-4 py-2 rounded-lg ${
            currentPage === totalPages
              ? "bg-gray-300"
              : "bg-blue-500 text-white"
          }`}
        >
          Next
        </button>
      </div>

      {showPopup && selectedItemIndex !== null && selectedItemIndex ? (
        <EditForm
          selectedItem={selectedItemIndex?.employee} // Use optional chaining
          onUpdate={handleUpdateData}
          onClose={handleEditFormClose}
          showRewardField={true}
        />
      ) : null}
    </div>
  );
};

export default EmployeesTable;
