import { useState } from "react";
import Overview from "./Overview";

import {
  FaSort,
  FaSortUp,
  FaSortDown,
  FaStar,
  FaEye,
  FaEdit,
  FaTrash,
  FaFilter,
  FaTrophy,
} from "react-icons/fa";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GoSearch } from "react-icons/go";
import EmployeesTable from "./LeaderBoard/EmployeesTable";
import LeadersChart from "./LeaderBoard/LeadersChart";
import PastWinners from "./LeaderBoard/PastWinners";
import Leaders from "./LeaderBoard/Leaders";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const LeaderBoard = () => {
  return (
    <>
      <Overview title="Leaderboard" showExportButton={false} />
      <Leaders />
      <div className="grid grid-cols-12 gap-8 mt-8">
        <div className="col-span-12 md:col-span-5">
          <PastWinners />
        </div>

        <div className="col-span-12 md:col-span-7">
          <LeadersChart />
        </div>
      </div>

      <EmployeesTable />
    </>
  );
};

export default LeaderBoard;
