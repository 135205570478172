import React, { useEffect, useState } from "react";
import axios from "axios";
import { HiChartPie, HiSparkles, HiLightBulb } from "react-icons/hi";
import Subscription from "./Subscription";
import { useOutletContext } from "react-router-dom";

function AIInsights() {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const [companyFirstName, setCompanyFirstName] = useState("");
  const { isExpired } = useOutletContext();

  useEffect(() => {
    // Load insights from localStorage on mount
    const savedInsights = localStorage.getItem("insights");
    if (savedInsights) {
      setInsights(JSON.parse(savedInsights));
    }
  }, []);

  useEffect(() => {
    // Update time and fetch company name
    const updateTime = () => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      setCurrentTime(formattedTime);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    // Get company name from localStorage
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const companyName = userData.company_name || "Default Company";
    const firstName = companyName.split(" ")[0];
    setCompanyFirstName(firstName);

    return () => clearInterval(interval);
  }, []);

  const fetchInsights = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.get(
        "https://zola.technology/api/ai-insights/company/get/",
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setInsights(response.data);
      localStorage.setItem("insights", JSON.stringify(response.data));
    } catch (err) {
      console.error("Failed to fetch insights", err);
      setInsights(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3 md:p-6 bg-white dark:bg-gray-900 h-fit w-full">
      {/* Header */}
      {isExpired && (
        <Subscription />
      )}
      <div className="flex flex-col md:flex-row justify-between gap-4 items-center mb-6">
        <div>
          <h1 className="text-2xl text-center md:text-start font-bold text-gray-800 dark:text-gray-100">
            AI Insights
          </h1>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Zola AI will be your feedback explorer
          </p>
        </div>

        <button
          onClick={fetchInsights}
          disabled={loading}
          className={`px-4 py-2 border rounded-lg flex items-center gap-1 transition ${
            loading
              ? "bg-gray-300 text-gray-600 cursor-not-allowed"
              : "text-blue-500 border-blue-500 hover:bg-blue-100 dark:hover:bg-blue-800 dark:text-blue-400 dark:border-blue-400"
          }`}
        >
          {loading ? "Generating..." : "Generate Insights"}
          <HiSparkles
            className={
              loading
                ? "animate-spin text-gray-500"
                : "text-blue-500 dark:text-blue-400"
            }
          />
        </button>
      </div>

      {/* Insights Summary */}
      {/* <div className="flex flex-col lg:flex-row items-center justify-center gap-4 lg:gap-16 rounded-lg p-4 mb-6">
        <button className="flex items-center gap-2 px-4 py-2 text-gray-600 dark:text-gray-300 border border-gray-300 dark:border-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition">
          <span className="text-lg">‹</span>
          <span className="text-sm font-medium">Previous Insights</span>
        </button>

        <div className="flex items-center gap-2 px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg text-gray-600 dark:text-gray-300">
          <span className="text-lg">📅</span>
          <span className="text-sm font-medium">
            {currentTime} – Summary for {companyFirstName}
          </span>
        </div>

        <button className="flex items-center gap-2 px-4 py-2 text-gray-600 dark:text-gray-300 border border-gray-300 dark:border-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 transition">
          <span className="text-sm font-medium">Next Insights</span>
          <span className="text-lg">›</span>
        </button>
      </div> */}

      {/* AI Insights Data */}
      <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-sm rounded-lg w-full p-3 md:p-6">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100 mb-6">
          Summary
        </h2>

        {/* No Insights */}
        {!loading && !insights && (
          <div className="flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 shadow-md">
            <div className="flex items-center justify-center w-16 h-16 bg-blue-100 dark:bg-blue-900 text-blue-500 dark:text-blue-300 rounded-full mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-3.866 0-7 3.134-7 7a7 7 0 0014 0c0-3.866-3.134-7-7-7zm0 0c2.485-1.714 5-4.714 5-7H7c0 2.286 2.515 5.286 5 7z"
                />
              </svg>
            </div>
            <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
              No Insights Available
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-300 text-center mt-2">
              Click the <strong>"Generate Insights"</strong> button above to
              create a new summary for your business. Our AI is ready to help!
            </p>
          </div>
        )}

        {/* Loading State */}
        {loading && (
          <p className="text-gray-600 dark:text-gray-300 text-lg font-medium">
            Generating insights... Please wait.
          </p>
        )}

        {/* Insights Data */}
        {!loading && insights && (
          <div className="space-y-8">
            {/* Sentiment Distribution */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
                <span className="text-blue-500">
                  <HiChartPie className="w-6 h-6" />
                </span>
                Sentiment Distribution
              </h3>
              <p className="text-gray-700 dark:text-gray-300 flex flex-col md:flex-row mt-4">
                <span className="text-green-500 font-semibold mr-2">
                  Positive:
                </span>{" "}
                {insights.sentiment_distribution.positive}
                <span className="hidden md:inline mx-2">|</span>{" "}
                <span className="text-gray-500 font-semibold mr-2">
                  Neutral:
                </span>{" "}
                {insights.sentiment_distribution.neutral}
                <span className="hidden md:inline mx-2">|</span>{" "}
                <span className="text-red-500 font-semibold mr-2">
                  Negative:
                </span>{" "}
                {insights.sentiment_distribution.negative}
              </p>
            </div>

            {/* Key Themes & Trends */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
                <span className="text-yellow-500">
                  <HiLightBulb />
                </span>
                Key Themes & Trends
              </h3>
              <ul className="list-disc pl-6 mt-4 text-gray-700 dark:text-gray-300 space-y-2">
                {insights.key_themes_and_trends.map((item, index) => (
                  <li key={index}>
                    <span className="font-semibold">{item.theme}:</span>{" "}
                    {item.summary} (
                    <span
                      className={`${
                        item.sentiment === "Positive"
                          ? "text-green-500"
                          : item.sentiment === "Negative"
                          ? "text-red-500"
                          : "text-gray-500"
                      } font-medium`}
                    >
                      {item.sentiment}
                    </span>
                    )
                  </li>
                ))}
              </ul>
            </div>

            {/* Strengths & Weaknesses */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
                Strengths & Weaknesses
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                <div>
                  <h4 className="text-md font-bold text-green-500 mb-2">
                    Strengths
                  </h4>
                  <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
                    {insights.strengths_and_weaknesses.strengths.map(
                      (strength, i) => (
                        <li key={i}>{strength}</li>
                      )
                    )}
                  </ul>
                </div>
                <div>
                  <h4 className="text-md font-bold text-red-500 mb-2">
                    Weaknesses
                  </h4>
                  <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
                    {insights.strengths_and_weaknesses.weaknesses.map(
                      (weakness, i) => (
                        <li key={i}>{weakness}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>

            {/* Actionable Recommendations */}
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-lg p-6 border border-gray-200 dark:border-gray-700">
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center gap-2">
                <span className="text-blue-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </span>
                Actionable Recommendations
              </h3>
              <ul className="list-disc pl-6 mt-4 text-gray-700 dark:text-gray-300 space-y-2">
                {insights.actionable_recommendations.map(
                  (recommendation, i) => (
                    <li key={i}>{recommendation}</li>
                  )
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AIInsights;
