import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import zola from "./zola-logo.png";
import zolaLight from "./zola-light.png";
import { motion } from "framer-motion";
import { buttonBaseClasses } from "@mui/material";

const isColorDark = (color) => {
  const hex = color.replace("#", "");

  // Convert 3-character hex to 6-character hex
  const normalizedHex =
    hex.length === 3
      ? hex
          .split("")
          .map((char) => char + char)
          .join("")
      : hex;

  if (normalizedHex.length !== 6) {
    console.error("Invalid hex color:", color);
    return false; // Default to light if invalid
  }

  // Parse the hex color to get RGB values
  const r = parseInt(normalizedHex.substr(0, 2), 16);
  const g = parseInt(normalizedHex.substr(2, 2), 16);
  const b = parseInt(normalizedHex.substr(4, 2), 16);

  // Calculate relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Return true if the color is dark
  return luminance < 128;
};

const MobileDataPresent = ({
  bgColor,
  coverImage,
  companyLogo,
  welcomeMessage,
  visitMessage,
  buttons,
  textColor,
  handleLeaveReview,
  facebookUrl,
  instagramUrl,
  twitterUrl,
  fontFamily,
  handleTakeSurvey,
}) => {
  const [showSocialIcons, setShowSocialIcons] = useState(false);

  // Determine dynamic text color
  const dynamicTextColor = isColorDark(bgColor) ? "#FFFFFF" : "#000000";

  console.log("Logo Source:", isColorDark(bgColor) ? zolaLight : zola);

  return (
    <div
      className="relative overflow-hidden h-[100dvh] max-w-md mx-auto transition duration-300 transform"
      style={{ backgroundColor: bgColor, fontFamily }}
    >
      {/* Cover Image */}
      <div className="relative">
        <motion.img
          src={coverImage}
          alt="Company Cover"
          className="w-full h-48 object-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.img
          src={companyLogo}
          alt="Company Logo"
          className="absolute -bottom-16 left-4 bg-white w-36 h-36 rounded-full border-4 border-gray-200 shadow-lg object-contain"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
        />
      </div>

      {/* Welcome Section */}
      <div className="mt-16 text-center px-6" style={{ color: textColor }}>
        <motion.h1
          className="text-3xl font-extrabold mb-2"
          initial={{ y: -30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {welcomeMessage}
        </motion.h1>
        <motion.p
          className="text-lg mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {visitMessage}
        </motion.p>
      </div>

      <div className="flex flex-col items-center mb-8 px-6">
        {buttons &&
          Object.values(buttons).map((button, index) => {
            // Check if the button should be hidden
            if (button.isHidden) {
              return null; // Skip rendering this button
            }

            // Check if the button is the "Follow Us On Social Media" button
            if (button.label === "Follow Us On Social Media") {
              const socialMedia = button.socialMedia || {};
              const hasSocialMediaLinks =
                socialMedia.facebook ||
                socialMedia.instagram ||
                socialMedia.twitter;

              if (!hasSocialMediaLinks) {
                return null; // Don't render the button if no social media URLs are found
              }
            }

            return (
              <motion.button
                key={index}
                className="p-4 w-3/4 mb-4 text-lg font-medium rounded-md transition duration-200 transform hover:scale-105 shadow-md hover:shadow-lg"
                style={{
                  backgroundColor: button.bgColor || "#1E40AF",
                  color: "#ffffff",
                }}
                aria-label={`Button for ${button.label}`}
                onClick={() => {
                  if (index === 0) {
                    handleLeaveReview(); // Trigger handleLeaveReview if index is 0
                  } else if (button.label === "Follow Us On Social Media") {
                    setShowSocialIcons((prev) => !prev); // Toggle social icons visibility
                  } else if (button.fancyId === "survey-button" || buttons.button_3) {
                    handleTakeSurvey(); // Trigger survey function if button has fancyId "survey-button"
                  }
                }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                {button.label}
              </motion.button>
            );
          })}
      </div>

      {/* Social Media Icons */}
      {showSocialIcons && (
        <motion.div
          className="flex space-x-4 justify-center mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {facebookUrl && (
            <a
              href={facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full bg-white shadow-xl transform hover:scale-110 transition duration-300"
            >
              <FaFacebook className="text-blue-600 w-6 h-6" />
            </a>
          )}
          {instagramUrl && (
            <a
              href={instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full bg-white shadow-xl transform hover:scale-110 transition duration-300"
            >
              <FaInstagram className="text-pink-600 w-6 h-6" />
            </a>
          )}
          {twitterUrl && (
            <a
              href={twitterUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center w-12 h-12 rounded-full bg-white shadow-xl transform hover:scale-110 transition duration-300"
            >
              <FaTwitter className="text-blue-400 w-6 h-6" />
            </a>
          )}
        </motion.div>
      )}

      {/* Footer */}
      <motion.footer
        className="absolute bottom-4 text-center w-full"
        style={{ color: dynamicTextColor }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <p className="text-lg flex items-center justify-center gap-1">
          Powered by{" "}
          <motion.img
            src={isColorDark(bgColor) ? zolaLight : zola}
            alt="Zola Logo"
            className="w-28"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
          />
        </p>
      </motion.footer>
    </div>
  );
};

const MobileDataAbsent = ({
  profileImage,
  preferredBusinessName,
  handleLeaveReview,
  handleComingSoon,
}) => {
  return (
    <section className="min-h-screen flex flex-col items-center bg-white dark:bg-gray-800 transition duration-300">
      <div className="relative w-full h-60">
        <img
          src={profileImage}
          alt="Company Team"
          className="w-full h-60 object-cover shadow-lg transition-opacity duration-500"
        />
        <div className="absolute top-52 left-1/4 transform -translate-x-1/2 bg-blue-500 p-4 rounded-md text-white font-semibold max-w-[80%] text-center">
          {preferredBusinessName}
        </div>
      </div>
      <div className="mt-8 text-center px-4">
        <h1 className="text-2xl font-semibold mb-1 text-gray-900 dark:text-gray-100">
          Hi there 👋
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-400 mb-6">
          Thanks for being our visitor today!
        </p>
        <div className="space-y-4 w-full px-6">
          <button
            onClick={handleLeaveReview}
            className="w-full py-3 bg-white border border-gray-300 rounded-md text-lg font-semibold hover:bg-gray-100 transition dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            aria-label="Leave a review"
          >
            Leave Us A Review
          </button>
          <button
            onClick={handleComingSoon}
            className="w-full py-3 bg-white border border-gray-300 rounded-md text-lg font-semibold hover:bg-gray-100 transition dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            aria-label="Refer us to a friend"
          >
            Refer Us To A Friend
          </button>
          <button
            onClick={handleComingSoon}
            className="w-full py-3 bg-white border border-gray-300 rounded-md text-lg font-semibold hover:bg-gray-100 transition dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            aria-label="Follow us on Instagram"
          >
            Follow Us On Instagram
          </button>
        </div>
      </div>
      <footer className="absolute bottom-4 text-center text-white">
        <p className="text-lg">
          Powered by <span className="font-semibold">Zola Technologies</span>
        </p>
      </footer>
    </section>
  );
};

const CompanyDetails = () => {
  const { company_username, employee_username, channel } = useParams();
  const [preferredBusinessName, setPreferredBusinessName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [imageLoading, setImageLoading] = useState(true);
  const [mobileData, setMobileData] = useState(null);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const navigate = useNavigate();
  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [fontFamily, setFontFamily] = useState("");

  useEffect(() => {
    const fetchBusinessName = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/get/${company_username}`
        );
        setPreferredBusinessName(response.data.preferred_business_name);
        setProfileImage(response.data.profile_image);
        setFontFamily(response.data.font_family);

        localStorage.setItem("selectedFont", response.data.font_family);
      } catch (error) {
        console.error("Error fetching company profile:", error);
      }
    };
    fetchBusinessName();
  }, [company_username]);

  useEffect(() => {
    const fetchMobileData = async () => {
      try {
        const response = await axios.get(
          `https://zola.technology/api/company/${company_username}/feedback/mobile/get-mobile-page/`
        );
        console.log(response);
        setMobileData(response.data);
        const buttons = response.data.buttons;
        const socialMedia = buttons.button_2?.socialMedia || {}; // Assuming button_2 is the one that contains socialMedia

        setFacebookUrl(socialMedia.facebook || "");
        setInstagramUrl(socialMedia.instagram || "");
        setTwitterUrl(socialMedia.twitter || "");
      } catch (error) {
        console.error("Error fetching company mobile data:", error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };
    fetchMobileData();
  }, [company_username]);

  useEffect(() => {
    const storedFont = localStorage.getItem("selectedFont");
    if (storedFont) {
      setFontFamily(storedFont);
    }
  }, []);

  useEffect(() => {
    if (fontFamily) {
      const linkId = "google-font-link";
      let linkElement = document.getElementById(linkId);

      if (!linkElement) {
        linkElement = document.createElement("link");
        linkElement.id = linkId;
        linkElement.rel = "stylesheet";
        document.head.appendChild(linkElement);
      }

      linkElement.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(
        " ",
        "+"
      )}&display=swap`;
    }
  }, [fontFamily]);

  const handleLeaveReview = () => {
    navigate(
      `/employee_rating/${company_username}/${employee_username}/${
        channel || "n"
      }`,
      {
        state: {
          backgroundColor: mobileData?.bg_color, // Passing the background color
          textColor: mobileData?.text_color, // Passing the text color
        },
      }
    );
  };

  const handleTakeSurvey = () => {
    navigate(`/survey/${company_username}/${channel || "n"}`);
  };

  const handleComingSoon = () => {
    setShowComingSoon(true);
  };

  const resetComingSoon = () => {
    setShowComingSoon(false);
  };

  if (showComingSoon) {
    return (
      <ComingSoon
        companyUsername={company_username}
        employeeUsername={employee_username}
        onGoBack={resetComingSoon}
      />
    );
  }

  if (loading) {
    // Show a loading spinner or message while data is being fetched
    return <></>;
  }

  return (
    <>
      {mobileData ? (
        <MobileDataPresent
          bgColor={mobileData.bg_color}
          coverImage={mobileData.cover_image}
          companyLogo={mobileData.company_logo}
          welcomeMessage={mobileData.welcome_message}
          visitMessage={mobileData.visit_message}
          buttons={mobileData.buttons}
          textColor={mobileData.text_color}
          handleLeaveReview={handleLeaveReview}
          facebookUrl={facebookUrl} // Use state variable here
          instagramUrl={instagramUrl} // Use state variable here
          twitterUrl={twitterUrl}
          fontFamily={fontFamily}
          handleTakeSurvey={handleTakeSurvey}
        />
      ) : (
        // <MobileDataAbsent
        //   profileImage={profileImage}
        //   preferredBusinessName={preferredBusinessName}
        //   handleLeaveReview={handleLeaveReview}
        //   handleComingSoon={handleComingSoon}
        // />
        handleLeaveReview()
      )}
    </>
  );
};

export default CompanyDetails;
