import { useParams, useNavigate } from "react-router-dom";
import React from "react";

const dummySurveys = [
  {
    id: "24",
    title: "Customer Satisfaction Survey",
    created_at: "2024-02-01T12:00:00Z",
    questions: [
      { text: "How satisfied are you with our service?" },
      { text: "Would you recommend us to others?" },
    ],
  },
  {
    id: "2",
    title: "Employee Feedback Survey",
    created_at: "2024-01-15T10:30:00Z",
    questions: [{ text: "How would you rate the work environment?" }],
  },
];

// Dummy responses for each survey
const dummyResponses = [
  {
    survey_id: "24",
    id: "101",
    respondent: "John Doe",
    submitted_at: "2024-02-02T09:00:00Z",
    answers: [
      { question: "How satisfied are you with our service?", answer: "Very Satisfied" },
      { question: "Would you recommend us to others?", answer: "Yes" },
    ],
  },
  {
    survey_id: "24",
    id: "102",
    respondent: "Jane Smith",
    submitted_at: "2024-02-03T11:30:00Z",
    answers: [
      { question: "How satisfied are you with our service?", answer: "Neutral" },
      { question: "Would you recommend us to others?", answer: "Maybe" },
    ],
  },
  {
    survey_id: "2",
    id: "103",
    respondent: "Alice Johnson",
    submitted_at: "2024-01-20T14:15:00Z",
    answers: [{ question: "How would you rate the work environment?", answer: "Excellent" }],
  },
];

function SurveyResponseDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  // Find the survey by ID
  const survey = dummySurveys.find((s) => s.id === id);
  if (!survey) {
    return <div className="p-6">Survey not found.</div>;
  }

  // Get responses for the selected survey
  const surveyResponses = dummyResponses.filter((response) => response.survey_id === id);

  return (
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-semibold">{survey.title}</h2>
      <p className="text-gray-600">
        Created on: {new Date(survey.created_at).toLocaleString()}
      </p>

      {/* Responses Table */}
      <h3 className="text-xl mt-6 mb-3">Survey Responses</h3>
      {surveyResponses.length === 0 ? (
        <p className="text-gray-600">No responses yet for this survey.</p>
      ) : (
        <table className="min-w-full table-auto border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b text-left">No</th>
              <th className="py-3 px-4 border-b text-left">Respondent</th>
              <th className="py-3 px-4 border-b text-left">Submitted Date</th>
              <th className="py-3 px-4 border-b text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {surveyResponses.map((response, index) => (
              <tr key={response.id} className="hover:bg-gray-50">
                <td className="py-3 px-4 border-b">{String(index + 1).padStart(2, "0")}</td>
                <td className="py-3 px-4 border-b">{response.respondent}</td>
                <td className="py-3 px-4 border-b">
                  {new Date(response.submitted_at).toLocaleString()}
                </td>
                <td className="py-3 px-4 border-b">
                  <button
                    onClick={() => navigate(`/dashboard/survey-response-details/${response.id}`)}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md shadow hover:bg-blue-700 transition"
                  >
                    View Response
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SurveyResponseDetails;
