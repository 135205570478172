import { useEffect, useState } from "react";
import { useTimeRange } from "../../context/TimeRangeContext";

const ResponsesList = () => {
  const [responses, setResponses] = useState([]);
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchResponses = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch(
          `https://zola.technology/api/company/surveys/get-responses/?filter=${timeRange}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch responses");
        }

        const data = await response.json();
        setResponses(
          data.map((item) => ({
            response: item.response,
            updatedAt: new Date(item.updated_at).toLocaleString(),
          }))
        );
      } catch (error) {
        console.error("Error fetching responses:", error);
      }
    };

    fetchResponses();
  }, [timeRange]);

  return (
    <div className="p-6 bg-white rounded-md overflow-y-auto">
      <h3 className="text-lg font-semibold mb-4">Responses: Open ended</h3>
      {responses.length > 0 ? (
        responses.map((response, idx) => (
          <div
            key={idx}
            className="flex items-start gap-4 py-4 border-b last:border-none"
          >
            <div className="p-2 text-white bg-blue-500 rounded-full">✓</div>
            <div>
              <p>{response.response}</p>
              <span className="text-xs text-gray-500">{response.updatedAt}</span>
            </div>
          </div>
        ))
      ) : (
        <p className="text-gray-500">No responses available.</p>
      )}
    </div>
  );
};

export default ResponsesList;
