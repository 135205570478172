import React, { useState } from "react";
import {
  FaStar,
  FaEye,
  FaTrashAlt,
  FaExclamationTriangle,
} from "react-icons/fa";
import { BiSolidEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import EditBranchPopup from "./EditBranch";
function BranchTable({ paginatedBranches }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [editData, setEditData] = useState({});
  const [errors, setErrors] = useState({}); // State to store validation errors
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const navigate = useNavigate();

  // Function to handle redirection when "Details" is clicked
  const handleDetailsClick = (branchId) => {
    navigate(`/hqdashboard/branchdashboard/${branchId}`);
  };

  // Handle Edit and Delete button clicks
  const handleActionClick = (branch, action) => {
    if (action === "edit") {
      setEditData({
        name: branch.name,
        employees: branch.employees,
        performance: branch.performance,
        positive: branch.positive,
        negative: branch.negative,
        taps: branch.taps,
      });
      setSelectedBranch(branch);
      setIsPopupOpen(true);
    } else if (action === "delete") {
      setSelectedBranch(branch);
      setIsDeleteConfirmationOpen(true);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedBranch(null);
    setEditData({});
    setErrors({})
  };

  const token = localStorage.getItem("token");
  // Handle form submission for editing

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setErrors({}); // Clear previous errors

    try {
      const response = await fetch(
        `https://zola.technology/api/hq-company/branches/update-branch/${selectedBranch.username}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editData),
        }
      );

      if (response.ok) {
        toast.success("Branch updated successfully!");
        handleClosePopup();
      } else {
        const errorData = await response.json();
        if (errorData && typeof errorData === "object") {
          setErrors(errorData); // Store field-specific errors
        } else {
          toast.error("Error updating branch. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error updating branch:", error);
      toast.error("Error Updating branch!");
    }
  };

  // Handle delete confirmation
  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://zola.technology/api/hq-company/branches/delete-branch/${selectedBranch.username}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        }
      );

      if (response.ok) {
        toast.success("Branch deleted successfully!");
        setIsDeleteConfirmationOpen(false);
      } else {
        toast.error("Error deleting branch!");
      }
    } catch (error) {
      console.error("Error deleting branch:", error);
      toast.error("Error deleting branch!");
    }
  };

  return (
    <div className="overflow-x-auto">
      <ToastContainer />
      <table className="min-w-full table-auto border-separate border-spacing-y-3">
        <thead>
          <tr className="text-gray-500 bg-gray-50">
            <th className="px-4 py-3 text-left">
              <input type="checkbox" />
            </th>
            <th className="px-4 py-3 text-left">S/N</th>
            <th className="px-4 py-3 text-left">Name</th>
            <th className="px-4 py-3 text-left">People</th>
            <th className="px-4 py-3 text-left">Performance</th>
            <th className="px-4 py-3 text-left">Issues</th>
            <th className="px-4 py-3 text-left">Engagement</th>
            <th className="px-4 py-3 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {paginatedBranches.map((branch, index) => (
            <tr
              key={branch.id}
              className="bg-white text-gray-700 rounded-lg shadow-sm hover:shadow-md transition transform"
            >
              <td className="px-4 py-3">
                <input type="checkbox" className="mr-2" />
              </td>
              <td className="px-4 py-3">
                {String(index + 1).padStart(2, "0")}
              </td>
              <td className="px-4 py-3 flex items-center gap-3">
                <div className="w-10 h-10 rounded-full bg-blue-500 text-white flex items-center justify-center text-sm font-bold">
                  {branch.name
                    .split(" ")
                    .map((word) => word[0])
                    .slice(0, 2)
                    .join("")
                    .toUpperCase()}
                </div>
                <span>{branch.name}</span>
              </td>
              <td className="px-4 py-3">{branch.employees.toLocaleString()}</td>
              <td className="px-4 py-3">{branch.performance}%</td>
              <td className="px-4 py-3">
                <span className="text-green-500 bg-green-100 px-2 py-1 mr-2 rounded text-xs font-semibold">
                  {branch.positive || 0}
                </span>
                <span className="text-red-500 bg-red-100 px-2 py-1 rounded text-xs font-semibold">
                  {branch.negative || 0}
                </span>
              </td>
              <td className="px-4 py-3">{branch.taps || 0}</td>
              <td className="px-4 py-3 flex items-center gap-2">
                <button
                  aria-label="View"
                  onClick={() => handleDetailsClick(branch.id)}
                  className="bg-yellow-500 text-white p-2 rounded-full shadow-md hover:shadow-lg hover:bg-yellow-600 transition-transform transform hover:scale-110 duration-200 ease-in-out"
                >
                  <FaEye />
                </button>

                <button
                  aria-label="Edit"
                  onClick={() => handleActionClick(branch, "edit")}
                  className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:shadow-lg hover:bg-blue-600 transition-transform transform hover:scale-110 duration-200 ease-in-out"
                >
                  <BiSolidEditAlt />
                </button>

                <button
                  aria-label="Delete"
                  onClick={() => handleActionClick(branch, "delete")}
                  className="bg-red-500 text-white p-2 rounded-full shadow-md hover:shadow-lg hover:bg-red-600 transition-transform transform hover:scale-110 duration-200 ease-in-out"
                >
                  <FaTrashAlt />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Popup Modal */}
      {isPopupOpen && (
        <EditBranchPopup
          selectedBranch={selectedBranch}
          editData={editData}
          setEditData={setEditData}
          handleEditSubmit={handleEditSubmit}
          handleClosePopup={handleClosePopup}
          errors={errors} // Pass errors to the popup
        />
      )}

      {/* Delete Confirmation Popup */}
      {isDeleteConfirmationOpen && selectedBranch && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div
            className="bg-white rounded-xl p-6 w-[90%] max-w-sm text-center space-y-4"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <FaExclamationTriangle className="text-[#EE6F7C] text-5xl mx-auto" />
            <h2 className="text-2xl text-[#454861] font-semibold">
              Are you sure you want to delete {selectedBranch.name} branch?
            </h2>
            <p className="text-[#6F707E] font-normal text-[20px]">
              This action cannot be undone.
            </p>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                aria-label="cancel"
                className="bg-[#0C4EF8] text-[#FEFEFF] font-semibold px-8 py-1 rounded-[4px] hover:bg-blue-700 transition"
                onClick={() => setIsDeleteConfirmationOpen(false)} // Closes the delete confirmation modal
              >
                Cancel
              </button>
              <button
                aria-label="delete"
                className=" border border-[#F7BCC2] text-[#E6283B] font-semibold px-8 py-1 rounded-[4px] hover:bg-red-600 hover:text-white transition"
                onClick={handleDelete} // Confirms deletion of the selected branch
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BranchTable;
