import React from "react";
import Chart from "react-apexcharts";
import SpeedometerChart from "./SpeedometerChart";
import GaugeChart from "./SpeedometerChart";
import SemiCircleChart from "./SpeedometerChart";

const SurveyDashboard = () => {
  const npsGaugeOptions = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "60%" },
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
        },
        dataLabels: {
          name: { show: false },
          value: { offsetY: 5, fontSize: "20px", fontWeight: "bold" },
        },
      },
    },
    colors: ["#28a745"],
    labels: ["NPS Score"],
  };

  const npsGaugeSeries = [-0.75];

  const promoterChartOptions = {
    chart: { type: "bar" },
    plotOptions: {
      bar: { horizontal: true, barHeight: "50%" },
    },
    colors: ["#28a745", "#f39c12", "#e74c3c"],
    dataLabels: { enabled: false },
    xaxis: { categories: ["Promoters", "Passives", "Detractors"] },
  };

  const promoterChartSeries = [
    {
      data: [94.92, 0, 1.08],
    },
  ];

  const columnChartOptions = {
    chart: { type: "bar" },
    colors: ["#5a32d8"],
    xaxis: {
      categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
    dataLabels: { enabled: false },
  };

  const columnChartSeries = [
    {
      name: "Ratings",
      data: [1.02, 0.05, 0, 0, 0, 0, 0, 0, 0, 98.83],
    },
  ];

  return (
    <div className="p-6 bg-gray-100">
      <div className="mx-auto bg-white p-6 rounded-lg shadow-lg">
        {/* Header */}
       

        {/* Grid Layout */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
          {/* NPS Gauge Chart */}
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-md font-semibold mb-2">
              How likely are you to recommend
            </h2>
            <p className="text-xs text-gray-500">
              Scratch Survey (All Questions)
            </p>
            <p className="text-xs text-gray-500">Responses: 242,058</p>
            <SemiCircleChart value={npsGaugeSeries[0]} />            
  
          </div>

          {/* Promoters/Detractors Bar Chart */}
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-md font-semibold mb-2">
              How likely are you to recommend
            </h2>
            <p className="text-xs text-gray-500">
              Scratch Survey (All Questions)
            </p>
            <p className="text-xs text-gray-500">Responses: 242,058</p>
            <Chart
              options={promoterChartOptions}
              series={promoterChartSeries}
              type="bar"
              height={180}
            />
            <ul className="text-sm mt-2">
              <li className="text-green-600">✔ 94.92% - 239.3K Promoters</li>
              <li className="text-yellow-600">⚠ 0% - 2 Passives</li>
              <li className="text-red-600">❌ 1.08% - 2.7K Detractors</li>
            </ul>
          </div>

          {/* Survey Responses */}
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-md font-semibold mb-2">Survey Responses</h2>
            <p className="text-xs text-gray-500">
              Scratch Survey (All Questions)
            </p>
            <p className="text-xs text-gray-500">Responses: 252,141</p>
            <div className="flex flex-col items-center mt-4">
              <p className="text-3xl font-bold">252,141</p>
              <p className="text-sm text-gray-500">Total Responses Collected</p>
              <p className="text-xl font-semibold mt-2">103.5 Sec</p>
              <p className="text-sm text-gray-500">Average Completion Time</p>
            </div>
          </div>

          {/* Top Box Score */}
          <div className="p-4 bg-white rounded-lg shadow-md text-center">
            <h2 className="text-md font-semibold mb-2">
              How likely are you to recommend
            </h2>
            <p className="text-xs text-gray-500">
              Scratch Survey (All Questions)
            </p>
            <p className="text-xs text-gray-500">Responses: 239,215</p>
            <p className="text-3xl font-bold mt-4">98.83%</p>
            <p className="text-sm text-gray-500">Top Box</p>
          </div>

          {/* Top 2 Box Score */}
          <div className="p-4 bg-white rounded-lg shadow-md text-center">
            <h2 className="text-md font-semibold mb-2">
              How likely are you to recommend
            </h2>
            <p className="text-xs text-gray-500">
              Scratch Survey (All Questions)
            </p>
            <p className="text-xs text-gray-500">Responses: 239,336</p>
            <p className="text-3xl font-bold mt-4">98.88%</p>
            <p className="text-sm text-gray-500">Top 2 Box</p>
          </div>

          {/* Column Chart */}
          <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-md font-semibold mb-2">
              How likely are you to recommend
            </h2>
            <p className="text-xs text-gray-500">
              Scratch Survey (All Questions)
            </p>
            <p className="text-xs text-gray-500">Responses: 242,058</p>
            <Chart
              options={columnChartOptions}
              series={columnChartSeries}
              type="bar"
              height={180}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyDashboard;
