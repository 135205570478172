import React, { useState } from "react";
import Overview from "./Overview";
import { FaStar, FaRegSmile, FaRegFrown, FaInfoCircle } from "react-icons/fa";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FaFilter, FaSort } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

function ManageFeedback() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  // Sample feedback data
  const feedbackData = [
    {
      id: 1,
      name: "Alexandra Botz",
      date: "06.11.2024",
      review:
        "I was looking for a CRM that was easy to use and could adapt to my business. This CRM has exceeded my expectations. It's simple, intuitive,...",
      stars: 5,
    },
    {
      id: 2,
      name: "Alexandra Botz",
      date: "06.11.2024",
      review:
        "I was looking for a CRM that was easy to use and could adapt to my business. This CRM has exceeded my expectations. It's simple, intuitive,...",
      stars: 5,
    },
    {
      id: 3,
      name: "Alexandra Botz",
      date: "06.11.2024",
      review:
        "I was looking for a CRM that was easy to use and could adapt to my business. This CRM has exceeded my expectations. It's simple, intuitive,...",
      stars: 5,
    },
    {
      id: 4,
      name: "Alexandra Botz",
      date: "06.11.2024",
      review:
        "I was looking for a CRM that was easy to use and could adapt to my business. This CRM has exceeded my expectations. It's simple, intuitive,...",
      stars: 5,
    },
  ];

  // Open modal function
  const openModal = (feedback) => {
    setSelectedFeedback(feedback);
    setIsModalOpen(true);
  };

  // Close modal function
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFeedback(null);
  };

  return (
    <div>
      <Overview title="Manage Replies" showExportButton={false} />
      <div className="bg-white px-6 mx-6 py-4 rounded-lg">
        <div className="">
          <div className="bg-blue-600 p-4 text-white rounded-xl flex justify-between items-center">
            <div className="grid">
              <div className="flex items-end text-5xl font-bold">
                <p>2,394</p>
                <span className="text-xs ml-2">
                  Total <br /> Reviews
                </span>
              </div>
              {/* Star Rating */}
              <div className="flex items-center text-lg mt-2 text-yellow-400">
                <span className="font-semibold text-3xl mr-1 text-white">
                  4.9
                </span>
                {[...Array(5)].map((_, i) => (
                  <FaStar key={i} />
                ))}
              </div>
            </div>

            <div className="grid grid-cols-4 gap-x-4 gap-y-2">
              <div className="grid gap-6 items-center">
                <OverviewCard
                  title="Requests Sent"
                  value="17,546"
                  icon={<FaInfoCircle />}
                  color="gray"
                />
                <OverviewCard
                  title="Requests Sent"
                  value="17,546"
                  icon={<FaInfoCircle />}
                  color="gray"
                />
              </div>
              <div className="grid gap-2 items-center">
                <OverviewCard
                  title="New Reviews"
                  value="46"
                  subtext="(Last 2 days)"
                  color="gray"
                />

                <OverviewCard
                  title="Requests Sent"
                  value="65%"
                  icon={<FaInfoCircle />}
                  color="gray"
                />
              </div>

              <div className="grid gap-2 items-center">
                <p className="text-sm uppercase font-medium -mt-2 tracking-wide">
                  Experience
                </p>

                <div className="flex gap-2 items-center">
                  <FaCheckCircle className="text-green-400 text-lg" />
                  <p className="text-3xl font-bold">97%</p>
                </div>

                <div className="flex gap-2 items-center">
                  <FaTimesCircle className="text-red-400 text-lg" />
                  <p className="text-3xl font-bold">3%</p>
                </div>
              </div>

              <div className="text-sm">
                <h3 className="text-lg font-semibold -mt-1">Teammates</h3>
                <div className="flex flex-col  gap-2 mt-2 justify-center">
                  <div className="flex items-center">
                    <img
                      src="https://randomuser.me/api/portraits/women/44.jpg"
                      alt="Amanda"
                      className="w-8 h-8 rounded-full border"
                    />
                    <span className="ml-2">Amanda</span>
                  </div>
                  <div className="flex items-center">
                    <img
                      src="https://randomuser.me/api/portraits/men/45.jpg"
                      alt="David"
                      className="w-8 h-8 rounded-full border"
                    />
                    <span className="ml-2">David</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section - Illustration */}
            <div>
              <img
                src="/illustration.png" // Replace with actual image path
                alt="Illustration"
                className="w-40 h-40"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center w-full mt-6 pb-2">
          <h2 className="text-lg font-semibold text-gray-800">Replies</h2>

          <div className="flex space-x-3">
            <button className="flex items-center gap-2 border px-4 py-2 text-sm rounded-md hover:bg-gray-100">
              <FaSort className="text-gray-600" />
              Sort By
            </button>
            <button className="flex items-center gap-2 border px-4 py-2 text-sm rounded-md hover:bg-gray-100">
              <FaFilter className="text-gray-600" />
              Filter
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 w-full gap-4">
          {feedbackData.map((feedback) => (
            <div
              key={feedback.id}
              className="bg-white p-4 rounded-lg shadow-md border w-full cursor-pointer hover:shadow-lg transition-all"
              onClick={() => openModal(feedback)}
            >
              {/* User Info */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <img
                    src="https://randomuser.me/api/portraits/women/44.jpg"
                    alt="User"
                    className="w-10 h-10 rounded-full"
                  />
                  <div>
                    <p className="font-semibold">{feedback.name}</p>
                    <p className="text-xs text-gray-500">{feedback.date}</p>
                  </div>
                  <span className="text-xs bg-red-100 text-red-600 px-2 py-1 rounded-md font-semibold">
                    NEW
                  </span>
                </div>

                {/* Google & Manage Button */}
                <div className="flex items-center space-x-2">
                  <FcGoogle className="text-red-500 text-2xl" />
                  <button className="bg-blue-600 text-white px-3 py-1 text-sm rounded-full">
                    Manage
                  </button>
                </div>
              </div>

              {/* Review Rating & Text */}
              <div className="mt-3">
                <div className="flex mb-4 space-x-1 text-yellow-400">
                  {[...Array(feedback.stars)].map((_, i) => (
                    <FaStar key={i} size={24} />
                  ))}
                </div>
                <p className="text-sm text-gray-800 mt-2">{feedback.review}</p>
              </div>

              {/* Reply Section */}
              <div className="mt-4 bg-blue-100 p-3 rounded-lg flex flex-col items-start">
                <div className="flex items-center space-x-2">
                  <img
                    src="https://randomuser.me/api/portraits/men/50.jpg"
                    alt="Reply"
                    className="w-10 h-10 rounded-full"
                  />
                  <p className="text-sm font-semibold">Reply</p>
                  <p className="text-xs text-gray-500">About 12 hours ago</p>
                </div>
                <p className="text-sm text-gray-700 mt-2">
                  I was looking for a CRM that was easy to use and could adapt
                  to my business. This CRM has exceeded my expectations. It's
                  simple, intu...
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && selectedFeedback && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[500px]">
            {/* Modal Header */}
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <img
                  src="https://randomuser.me/api/portraits/women/44.jpg"
                  alt="User"
                  className="w-10 h-10 rounded-full"
                />
                <div>
                  <p className="font-semibold">{selectedFeedback.name}</p>
                  <p className="text-xs text-gray-500">
                    {selectedFeedback.date}
                  </p>
                </div>
                <span className="text-xs bg-red-100 text-red-600 px-2 py-1 rounded-md font-semibold">
                  NEW
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <FcGoogle className="text-red-500 text-2xl" />
                <button className="bg-blue-600 text-white px-3 py-1 text-sm rounded-full">
                  Manage
                </button>
              </div>
            </div>

            {/* Review Rating & Text */}
            <div className="mt-3">
              <div className="flex space-x-1 text-yellow-400">
                {[...Array(selectedFeedback.stars)].map((_, i) => (
                  <FaStar key={i} size={24} />
                ))}
              </div>
              <p className="text-sm text-gray-800 mt-2">
                {selectedFeedback.review}
              </p>
            </div>

            {/* Reply Box */}
            <textarea
              className="w-full mt-3 border rounded-md p-2 text-sm"
              rows="3"
              placeholder="Write a reply..."
            ></textarea>

            {/* Buttons */}
            <div className="flex justify-between items-center mt-3">
              <button className="bg-blue-600 text-white px-4 py-2 text-sm rounded-md">
                Post Reply
              </button>
              <button className="border px-4 py-2 text-sm rounded-md">
                Use reply template
              </button>
            </div>

            {/* Close Button */}
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-black"
              onClick={closeModal}
            >
              ✖
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const OverviewCard = ({ title, value, subtext, icon, color }) => {
  return (
    <div className="flex flex-col items-center text-white">
      <div className="flex items-center gap-1">
        <div className="text-sm">{title}</div>
        {icon && <span className={`text-${color}-400 text-sm`}>{icon}</span>}
      </div>
      <div className="text-4xl text-center font-bold">
        {value}
        {subtext && <div className="text-xs text-gray-300">{subtext}</div>}
      </div>
    </div>
  );
};

export default ManageFeedback;
