import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useTimeRange } from "../../../context/TimeRangeContext";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const IncentiveDistribution = () => {
  const [data, setData] = useState([]);
  const [totalIncentive, setTotalIncentive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found in localStorage");
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/performance/rewards/?filter=${timeRange}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch data");

        const result = await response.json();
        if (!result.data || result.data.length === 0) {
          setError("No data available");
          setLoading(false);
          return;
        }

        const total = result.data.reduce(
          (acc, emp) => acc + (emp.reward_total || 0),
          0
        );

        const employees = result.data.slice(0, 3).map((emp, index) => ({
          name: emp.employee?.name || "Unknown",
          value: emp.reward_total || 0,
          color: ["#20222F", "#725DFF", "#FFBF00"][index] || "#ddd",
        }));

        setData(employees);
        setTotalIncentive(total);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [timeRange]);

  if (loading)
    return <div className="text-center text-gray-500 p-6">Loading...</div>;
  if (error)
    return <div className="text-center text-red-500 p-6">Error: {error}</div>;

  const chartData = {
    labels: data.map((item) => item.name),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item) => item.color),
        borderWidth: 0,
        hoverOffset: 6,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `$${tooltipItem.raw.toLocaleString()}`,
        },
      },
      legend: { display: false },
    },
    cutout: "75%", // Creates the donut effect
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="p-4 md:p-6 rounded-lg bg-white dark:bg-gray-800 flex flex-col w-full h-full shadow-md">
      {/* Title */}
      <div className="text-gray-600 dark:text-gray-300 font-semibold text-lg md:text-xl px-2 md:px-4 pt-2 md:pt-3">
        Incentive Distribution
      </div>

      {/* Responsive Layout */}
      <div className="flex flex-col md:flex-row items-center justify-between p-4 md:p-6 h-full gap-6">
        {/* Chart Section */}
        <div className="relative w-full md:w-2/3 flex justify-center h-[250px] md:h-[350px]">
          <Doughnut data={chartData} options={chartOptions} />

          {/* Centered Text in Rounded Div */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="bg-white dark:bg-gray-700 shadow-lg rounded-full w-20 h-20 md:w-28 md:h-28 flex flex-col items-center justify-center text-center">
              <span className="text-base md:text-lg font-bold text-gray-900 dark:text-gray-100">
                ${totalIncentive.toLocaleString()}
              </span>
              <span className="text-gray-500 dark:text-gray-400 text-xs md:text-sm">
                Incentive
              </span>
            </div>
          </div>
        </div>

        {/* Legend Section */}
        <div className="w-full md:w-1/3 flex flex-col gap-4 md:gap-6 mt-4 md:mt-0">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between text-sm md:text-lg w-full"
            >
              <div className="grid gap-1 md:gap-2">
                <span className="text-gray-700 dark:text-gray-300 font-medium text-sm md:text-base">
                  {item.name}
                </span>
                <div className="flex items-center">
                  <span
                    className="w-3 h-3 md:w-4 md:h-4 rounded-full inline-block mr-2"
                    style={{ backgroundColor: item.color }}
                  ></span>
                  <span className="text-gray-900 dark:text-gray-100 font-semibold text-sm md:text-base">
                    ${item.value.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IncentiveDistribution;
