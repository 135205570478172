import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LeadersChart = () => {
    const data = {
      labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
      datasets: [
        {
          label: "Reviews",
          data: [500, 750, 1200, 900],
          fill: true,
          backgroundColor: "rgba(59, 130, 246, 0.2)",
          borderColor: "rgba(59, 130, 246, 1)",
          pointBackgroundColor: "white",
          pointBorderColor: "rgba(59, 130, 246, 1)",
          tension: 0.3,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: { display: false },
        tooltip: { enabled: true },
      },
      scales: {
        x: {
          grid: { display: false },
        },
        y: {
          beginAtZero: true,
          grid: { color: "rgba(200, 200, 200, 0.2)" },
          ticks: { stepSize: 300 },
        },
      },
    };
  
    return (
      <div className="bg-white p-6 rounded-lg shadow-lg w-full h-[50vh]">
        <div className="flex items-center space-x-4 mb-4">
          <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
          <div>
            <p className="text-lg font-semibold">
              Total Reviews: <span className="font-bold">14,049</span>{" "}
              <span className="text-green-500">+12</span>
            </p>
            <p className="text-green-500 text-sm">+15% vs last month</p>
          </div>
        </div>
  
        <div className="h-[38vh] w-full">
          <Line data={data} options={options} />
        </div>
      </div>
    );
  };

export default LeadersChart;
