import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import IncentiveDistribution from "./IncentiveDonutChart";
import { useTimeRange } from "../../../context/TimeRangeContext";

const TotalRevenue = () => {
  const [data, setData] = useState([]);
  const [totalReward, setTotalReward] = useState(0);
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://zola.technology/api/company/employees/total-rewards/?filter=${timeRange}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const result = await response.json();
        setTotalReward(result.total_rewards || 0);
        setData(result.period_data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [timeRange]);

  const formatLabels = () => {
    if (timeRange === "month") {
      return data.map((item) => item.period.split(":")[0].trim()); // Extracts "Week 07"
    } else if (timeRange === "week") {
      return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]; // Fixed days of the week
    } else if (timeRange === "year") {
      return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; // Fixed months
    }
    return data.map((item) => item.period);
  };

  const areaChartOptions = {
    chart: {
      type: "area",
      height: "100%",
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    colors: ["#0057FF"],
    dataLabels: { enabled: false },
    stroke: { curve: "smooth", width: 2 },
    xaxis: {
      categories: formatLabels(),
      labels: { style: { fontSize: "12px", fontWeight: "medium", colors: "#6F707E" } },
    },
    yaxis: {
      labels: { formatter: (val) => `${val}`, style: { fontSize: "12px", colors: "#6F707E" } },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100],
        colorStops: [
          { offset: 0, color: "#0057FF", opacity: 0.4 },
          { offset: 100, color: "#0057FF", opacity: 0 },
        ],
      },
    },
    tooltip: { y: { formatter: (val) => `${val.toLocaleString()}` } },
    responsive: [
      {
        breakpoint: 768,
        options: {
          xaxis: {
            labels: { style: { fontSize: "10px" } },
          },
          yaxis: {
            labels: { style: { fontSize: "10px" } },
          },
        },
      },
    ],
  };

  const areaSeries = [{ name: "Incentive", data: data.map((item) => item.total_rewards || 0) }];

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
  {/* Total Incentive and Chart Section */}
  <div className="col-span-1 md:col-span-7 p-4 md:p-6 bg-white dark:bg-gray-800 rounded-xl shadow-md">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-lg md:text-xl font-semibold text-gray-700 dark:text-gray-300">
        Total Incentive
      </h2>
    </div>
    <p className="text-2xl md:text-3xl font-semibold text-gray-900 dark:text-gray-100">
      {totalReward.toLocaleString()}
    </p>
    <div className="w-full h-[250px] md:h-[350px]">
      <ReactApexChart
        options={areaChartOptions}
        series={areaSeries}
        type="area"
        height="100%"
      />
    </div>
  </div>

  {/* Incentive Distribution Section */}
  <div className="col-span-1 md:col-span-5 p-4 md:p-6 bg-white dark:bg-gray-800 rounded-xl shadow-md flex items-center justify-center">
    <IncentiveDistribution />
  </div>
</div>

  );
};

export default TotalRevenue;
