import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Logo from "./zolaDark.png";
import ZolaLogo from "./Layer_1.png";
import { AuthContext } from "../../../context/AuthContext";
import {
  FiGrid,
  FiBarChart2,
  FiGitBranch,
  FiMessageSquare,
  FiSend,
  FiBriefcase,
  FiDollarSign,
  FiHelpCircle,
  FiSettings,
} from "react-icons/fi";

const Sidebar = ({ theme, toggleTheme, toggleSidebar }) => {
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);

  const [sidebarVisible, setSidebarVisible] = useState(false); // State to control sidebar visibility

  if (!currentUser) {
    return null;
  }

  const isActive = (path) =>
    location.pathname === path
      ? "bg-blue-100 text-blue-600 border-r-4 border-blue-600 font-semibold"
      : theme === "light"
      ? "text-gray-900 hover:bg-blue-50"
      : "text-white hover:bg-gray-700";

  // Close sidebar when a link is clicked on smaller screens
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarVisible(false); // Close sidebar on link click
    }
  };

  return (
    <>
      {/* Button to toggle sidebar */}
      {!sidebarVisible && (
        <button
          className="md:hidden absolute w-[40px] h-[40px] top-5 left-2 z-50 text-gray-700 dark:text-white mr-5 bg-[#FFFFFF] rounded-[4px]"
          onClick={() => setSidebarVisible(true)}
          aria-label="Open sidebar"
        >
          <FaBars size={24} className="m-2" />
        </button>
      )}

      <div
        className={`fixed inset-y-0 transition-all duration-300 shadow-lg z-50 ${
          theme === "light"
            ? "bg-white text-gray-900"
            : "bg-gray-900 text-white"
        } w-64 transform ${
          sidebarVisible ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`} // Added responsive toggle for sidebar visibility
      >
        <div className="p-8">
          <img
            src={theme === "light" ? ZolaLogo : Logo}
            alt="Zola Logo"
            className="w-[150px] h-[30px] object-contain"
          />
        </div>

        <div className="py-2">
          <h2 className="text-sm font-semibold text-gray-400 mb-4 mx-4">
            Main Menu
          </h2>
          <ul>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard"
                className="flex items-center gap-4 w-full"
              >
                <FiGrid className="w-5 h-5" />
                Dashboard
              </Link>
            </li>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/analytics"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/analytics"
                className="flex items-center gap-4 w-full"
              >
                <FiBarChart2 className="w-5 h-5" />
                Analytics
              </Link>
            </li>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/branches"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/branches"
                className="flex items-center gap-4 w-full"
              >
                <FiGitBranch className="w-5 h-5" />
                Locations
              </Link>
            </li>
            {/* <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/messages"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/messages"
                className="flex items-center gap-4 w-full"
              >
                <FiMessageSquare className="w-5 h-5" />
                Messages
              </Link>
            </li> */}
            {/* <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/request-feedback"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/request-feedback"
                className="flex items-center gap-4 w-full"
              >
                <FiSend className="w-5 h-5" />
                Request Feedback
              </Link>
            </li> */}
          </ul>

          {/* <h2 className="text-sm font-semibold text-gray-400 my-4 mx-4">
            Other menu
          </h2>
          <ul>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/department"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/department"
                className="flex items-center gap-4 w-full"
              >
                <FiBriefcase className="w-5 h-5" />
                Department
              </Link>
            </li>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/payment"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/payment"
                className="flex items-center gap-4 w-full"
              >
                <FiDollarSign className="w-5 h-5" />
                Payment
              </Link>
            </li>
          </ul> */}

          {/* <h2 className="text-sm font-semibold text-gray-400 my-4 mx-4">
            Help & Settings
          </h2>
          <ul>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/help"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/help"
                className="flex items-center gap-4 w-full"
              >
                <FiHelpCircle className="w-5 h-5" />
                Help & Center
              </Link>
            </li>
            <li
              className={`flex items-center px-4 py-3 ${isActive(
                "/hqdashboard/settings"
              )}`}
            >
              <Link
                onClick={handleLinkClick}
                to="/hqdashboard/settings"
                className="flex items-center gap-4 w-full"
              >
                <FiSettings className="w-5 h-5" />
                Settings
              </Link>
            </li>
          </ul> */}
        </div>
        {/* Theme Toggle */}
        <div className="absolute bottom-0 w-full p-4  border-gray-300 dark:border-gray-700 flex items-center justify-between">
          <span className="text-sm font-medium">Dark mode</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only"
              checked={theme === "dark"}
              onChange={toggleTheme}
            />
            <div className="w-11 h-6 bg-gray-200 flex items-center rounded-full dark:bg-gray-600">
              <div
                className={`dot absolute w-4 h-4 bg-white rounded-full shadow-md transition ${
                  theme === "dark" ? "transform translate-x-6" : "ml-1"
                }`}
              ></div>
            </div>
          </label>
        </div>
      </div>

      {/* Overlay to close the sidebar when visible */}
      {sidebarVisible && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden z-40"
          onClick={() => setSidebarVisible(false)} // Close the sidebar when overlay is clicked
        ></div>
      )}
    </>
  );
};

export default Sidebar;
