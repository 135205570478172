import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useTimeRange } from "../../../context/TimeRangeContext";


const BranchPerformanceOverview = () => {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Performance",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: "#8c9098",
            fontSize: "12px",
          },
        },
        tickPlacement: "on",
      },
      yaxis: {
        labels: {
          style: {
            colors: "#8c9098",
            fontSize: "12px",
          },
          formatter: (value) => value.toLocaleString(),
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        theme: "dark",
        marker: {
          show: true,
        },
        y: {
          formatter: (value) => value.toLocaleString(),
        },
      },
      markers: {
        size: 6,
        colors: ["#fff"],
        strokeColors: "#0055ff",
        strokeWidth: 2,
        hover: {
          size: 8,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#D2DDFD"],
          shadeIntensity: 1,
          type: "vertical",
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0.1,
          stops: [0, 100],
        },
      },
      grid: {
        borderColor: "#e7e7e7",
      },
      colors: ["#0055ff"],
    },
  });

  
  const { timeRange } = useTimeRange();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in localStorage");
          return;
        }

        const response = await fetch(
          "https://zola.technology/api/hq-company/list/paginated-or-all-branch-companies/",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          console.error(`Failed to fetch data: ${response.status}`);
          return;
        }

        const data = await response.json();
        if (!Array.isArray(data)) {
          console.error("Invalid data structure", data);
          return;
        }

        const performanceScores = data.map((item) => item.performance_score);
        const branchNames = data.map(
          (item) => item.preferred_business_name || item.company_name
        );

        setChartData((prevData) => ({
          ...prevData,
          series: [
            {
              name: "Performance",
              data: performanceScores,
            },
          ],
          options: {
            ...prevData.options,
            xaxis: {
              ...prevData.options.xaxis,
              categories: branchNames,
            },
          },
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default BranchPerformanceOverview;
